import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {Container,Row, Col, Input } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from "../../../Components/Common/DeleteModal";
import { ToastContainer } from 'react-toastify';
import axios from "axios";
import 'react-phone-number-input/style.css'
import 'react-phone-number-input/mobile'
import TableContainer from '../../../Components/Common/TableContainer';
import { useMemo } from 'react';


//redux

const Professionnel = (props) => {
  document.title = props.t("Professionnels | Le Conseiller du BTP");
 
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  // Definition des etats
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false); 
    }, 2000); 
  }, []);

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };


  const [recrutements1, setRecrutements1] = useState([]);

  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://prod.leconseillerdubtp.com/api/v1/users/professionals/", {
          headers: {
            Authorization: 'Bearer ' + token
          },
        });
        setRecrutements1(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);
  
  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  
  const handleDeleteProduct = () => {
    if (recrutements1) {
      // dispatch(deleteProducts(articles.id));
      setDeleteModal(false);
    }
  };


  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      // dispatch(deleteProducts(element.value));
      del.style.display = 'none';
    });
  };


  const name1 =(j)=>{
    for(let i=0;i<=recrutements1.length;i++){
      if(j===recrutements1[i]?.email){
        if(recrutements1[i].profile){
          const profil= recrutements1[i].profile
          return profil.last_name;
        }else{
          return ""
        }
      }
    }
  }
  const prenom1 =(j)=>{
    for(let i=0;i<=recrutements1.length;i++){
      if(j===recrutements1[i]?.email){
        if(recrutements1[i].profile){
          const prenom= recrutements1[i].profile
          return prenom.first_name;
        }else{
          return " "
        }
      }
    }
  }
  const country1 =(j)=>{
    for(let i=0;i<=recrutements1.length;i++){
      if(j===recrutements1[i]?.email){
        if(recrutements1[i].profile){
          const country= recrutements1[i].profile
          return country.country;
        }else{
          return " "
        }
      }
    }
  }
  const picture1 =(j)=>{
    for(let i=0;i<=recrutements1.length;i++){
      if(j===recrutements1[i]?.id){
        if(recrutements1[i].profile){
          const picture= recrutements1[i].profile
          return picture.profile_pic;
        }else{
          return " "
        }
      }
    }
  }
  const type1 =(j)=>{
    for(let i=0;i<=recrutements1.length; i++){
      if(j===recrutements1[i]?.email){
        if(recrutements1[i].profile){
          const type= recrutements1[i].profile
          return type.user_type;
        }else{
          return " "
        }
      }
    }
  }

  const columns1 = useMemo(() => [
    {
      Header:props.t("IMAGE"),
      accessor:"profile_pic",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
               {picture1(recruitments.row.original.id) ? <img src={picture1(recruitments.row.original.id)}
                  className="avatar-xs rounded-circle"/> :
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                      {name1(recruitments.row.original.email).charAt(0)}
                    </div>
                  </div>
                }
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("NOM"),
      accessor:"last_name",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center ">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1 italic" style={{textTransform:'uppercase',fontWeight:'bold'}}>
                <Link
                  className="text-dark"
                >
                  {name1(recruitments.row.original.email)}
                </Link>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
        Header: props.t("PRENOM(S)"),
        accessor:"first_name",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center ">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 italic" style={{fontWeight:"bold"}}>
                  <Link
                    className="text-dark" 
                  >
                    {prenom1(recruitments.row.original.email)}
                  </Link>
                </h5>
              </div>
            </div>
          </>
        ),
    },
    {
      Header:props.t("EMAIL"),
      accessor:"email",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1 uppercase">
                {recruitments.row.original.email}
              </h5> 
            </div>
          </div>
        </>
      ),
    },
    {
        Header:props.t("TELEPHONE"),
        accessor:"phone_number",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 uppercase">
                  {recruitments.row.original.phone_number}
                </h5> 
              </div>
            </div>
          </>
        ),
    },
    {
        Header:props.t("TYPE"),
        accessor:"user_type",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 uppercase">
                  {type1(recruitments.row.original.email) && type1(recruitments.row.original.email)==="particular"?
                   (<h5 className="badge badge-soft-danger">Particulier</h5>):
                  type1(recruitments.row.original.email) && type1(recruitments.row.original.email)==="assistant"?
                  (<h5 className="badge badge-soft-primary">Assistant</h5>):
                  type1(recruitments.row.original.email) && type1(recruitments.row.original.email)==="professional"?
                  (<h5 className="badge badge-soft-info">Professionnel</h5>):type1(recruitments.row.original.email) && type1(recruitments.row.original.email)==="expert"?
                  (<h5 className="badge badge-soft-secondary">Expert</h5>):type1(recruitments.row.original.email) && type1(recruitments.row.original.email)==="commite"?
                  (<h5 className="badge badge-soft-secondary">Commité</h5>):
                  type1(recruitments.row.original.email) && type1(recruitments.row.original.email)==="enterprise"?
                  (<h5 className="badge badge-soft-secondary">Entreprise</h5>):

                  type1(recruitments.row.original.email) && type1(recruitments.row.original.email)==="organism"?
                  (<h5 className="badge badge-soft-secondary">Organisme</h5>): ""
                  }
                </h5> 
              </div>
            </div>
          </>
        ),
    },
    {
      Header:props.t("PAYS"),
      accessor:"country",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-11 uppercase badge badge-soft-warning">
                {country1(recruitments.row.original.email)}
              </h5> 
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t(" "),
      accessor:"",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
            {recruitments.row.original.professional && recruitments.row.original.professional?.banca_certificate && 
                <h5 className="fs-10 uppercase badge badge-soft-success">
                    {"Certifié"}
                  </h5>   

                }
              
            </div>
          </div>
        </>
      ),
    },
    {
      Header: "ACTIONS",
      Cell: (cellProps) => {
        const articlesData = cellProps.row.original;
        return(
          <>
            <li className="list-inline-item" title={props.t("Voir")}>
              <Link to={`profile-users/${articlesData.id}/`} className="text-warning d-inline-block"
                    >
                  <i className="ri-eye-fill align-bottom"></i>
              </Link>
           </li>
          </> 
        );
      },
    },
  ],
    [props,name1,type1,prenom1,country1,picture1]
  );

  document.title = props.t("Liste des utilisateurs professionnels");
  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={props.t("Liste des utilisateurs professionnels du BTP")} pageTitle={props.t("Listes des utilisateurs professionnels du BTP")} />
        <Row>
                              <div className="col-xl-12 col-lg-12">
                                <div>
                                  <div className="card">
                                    <div className="card-header border-0">
                                      <Row className="g-4 align-items-center">
                                        <Col sm={4}>
                                          <div className="search-box">
                                            <Input
                                              type="text"
                                              className="form-control search"
                                              placeholder="Rechercher..."
                                            />
                                            <i className="ri-search-line search-icon"></i>
                                          </div>
                                        </Col>
                                      </Row>
                                      <div className="row align-items-center">
                                      
                                        <div className="col-auto">
                                          <div id="selection-element">
                                            <div className="my-n1 d-flex align-items-center text-muted">
                                              Select{" "}
                                              <div
                                                id="select-content"
                                                className="text-body fw-semibold px-1"
                                              >{}</div>{" "}
                                              Result{" "}
                                              <button
                                                type="button"
                                                className="btn btn-link link-danger p-0 ms-3"
                                                onClick={() => setDeleteModalMulti(true)}
                                              >
                                                Remove
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-body pt-0">
                                      {   isLoading ? ( // If loading, show loading indicator
                                          <div className="py-4 text-center">
                                            <div>
                                              <lord-icon
                                                  src="https://cdn.lordicon.com/xjovhxra.json"
                                                  trigger="loop"
                                                  colors="primary:#b38a12,secondary:#02376b"
                                                  style={{ width: "72px", height:"72px" }}>
                                              </lord-icon>
                                            </div>
                                            <div className="mt-4">
                                              <h5>{props.t("Chargement")}...</h5>
                                            </div>
                                          </div>
                                        ) 
                                        :
                                      recrutements1 && recrutements1.length >= 0 ? (
                                        <TableContainer
                                          columns={columns1}
                                          data={(recrutements1 || [])}
                                          isAddUserList={false}
                                          customPageSize={10}
                                          divClass="table-responsive mb-1"
                                          tableClass="mb-0 align-middle "
                                          theadClass="table-light text-muted"
                                          isMetierFilter={false}
                                        />
                                      ) : (
                                        <div className="py-4 text-center">
                                          <div className="mt-4">
                                            <h5> {props.t("Désolé, pas de resultats trouvés")} </h5>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Row>
  

      </Container>
    </div>
  );
};

Professionnel.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}
export default withRouter(withTranslation()(Professionnel));