import React, { useEffect, useState} from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { EditorState} from 'draft-js';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import {
  Card,
  Col,
  Container,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";

// Redux
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const AssistancesAdd= (props) => {
 
  document.title = "Assistance réponse";
  const history = useHistory();

 
  const dateFormat = () => {
    let d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let h = (d.getHours() % 12) || 12;
    let ampm = d.getHours() < 12 ? "AM" : "PM";
    return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear() + ", " + h + ":" + d.getMinutes() + " " + ampm).toString());
  };



  const articlesId=useParams('id');
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const url = "https://prod.leconseillerdubtp.com/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
    };

    const [assistance, setAssistance] = useState({})
    const [response, setReponse] = useState([])
    useEffect(() => {
      const checkTokenValidity = async () => {
          try {
          const response = await axios.get(`${url}/assistances/${articlesId.id}/`, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          setAssistance(response)
          setReponse(assistance.response)
          } catch (error) {
          console.log(error)
          } 
      };
      checkTokenValidity();
    }, [assistance]);


  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      response_text:""
  },
    
    validationSchema: Yup.object().shape({
   
     }),
    onSubmit: (values) => {
          const formData= new FormData();
          formData.append("response_text",values.response_text);
          axios.post(`${url}/assistances/${articlesId.id}/respond/`,formData)
          .then(res =>{
            if(res){
              toast.success(props.t("Réponse envoyée"),{autoClose:3000})
            }
          }, (error) =>{
            if(error){
              toast.error(props.t("Réponse non envoyée"),{autoClose:3000})
            }}
          )
          validation.resetForm();
    }
  })
  
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.t("Assistance reponse")} pageTitle={props.t("Assistances")} />
        
        <Row>
          <Col lg={12}>
          <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Card className="p-4 h-screen">
            <div className="rounded p-4 " style={{border:'orange', borderStyle:'solid'}}> <Label>{props.t("QUESTION")}</Label> <div> {assistance && assistance.content_text}</div> </div>
              <div className="p-2">{assistance && assistance.request_date}</div>
              {response && response.map((item, key) => (
                <div key={key}>
                  <div className="rounded p-4 mt-4" style={{ border: 'green', borderStyle: 'solid', textAlign:'right' }} >
                    <Label>{props.t("REPONSE")}</Label>
                    <div>{item.response_text}</div>
                  </div>
                  <div className="p-2" style={{textAlign:'right'}}>{item.response_date}</div>
                </div>
                ))}
                  <Form encType="multipart/form-data"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit(); 
                    }}
                    >
                    <div className="mb-3" style={{position: 'sticky', bottom: '0'}}>
                      <textarea
                       className="form-control"
                       name="response_text"
                       value={validation.values.response_text}
                       onBlur={validation.handleBlur}
                       onChange={validation.handleChange}
                       rows="2"
                       cols="20"
                      />
                       {validation.errors.response_text && validation.touched.response_text? (
                        <FormFeedback type="invalid">{props.t(validation.errors.response_text)}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="text-end mb-3">
                      <button className="btn btn-primary mr-6">
                        <Link
                          to={`/apps-assistances`}
                          className="text-white"
                        >
                          {props.t("Retour")}
                        </Link>
                      </button>
                      <button type="submit" className="btn btn-warning m-3">
                        {props.t("Envoyer")}
                      </button>
                    </div> 
                  </Form>

            </Card>
          </div>   
          </Col>
        </Row>
      </Container>
    </div>
  );
};
AssistancesAdd.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(AssistancesAdd));

