import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import moment from "moment";

import {
  Container,
  Row
} from "reactstrap";

// RangeSlider
import "nouislider/distribute/nouislider.css";
import DeleteModal from "../../Components/Common/DeleteModal";
import TableContainer from "../../Components/Common/TableContainer";
import BreadCrumb from "../../Components/Common/BreadCrumb";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { red } from "@material-ui/core/colors";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

const Assistances = (props) => {

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const dispatch = useDispatch();


  // Definition des etats
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []);

  const [assistances, setAssistances] = useState([]);


  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://prod.leconseillerdubtp.com/api/v1/assistances/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setAssistances(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);


  const Response =(j)=>{
    for(let i=0;i<=assistances.length;i++){
      if(j===assistances[i]?.id){
        const responses= assistances[i]?.response
        return  responses.length;
      }
    }
  }

  console.log("dd",Response(3))

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  
  const handleDeleteProduct = () => {
    if (assistances) {
      // dispatch(deleteProducts(articles.id));
      setDeleteModal(false);
    }
  };


  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      // dispatch(deleteProducts(element.value));
      del.style.display = 'none';
    });
  };

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".entrepriseCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  }
  const columns = useMemo(() => [
    {
      Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
      Cell: (cellProps) => {
        return <input type="checkbox" className="entrepriseCheckBox" value={cellProps.row.original.id} />;
      },
      id: '#',
    },
    {
      Header:props.t("UTILISATEUR"),
      Cell: (assistance) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1" style={{fontWeight:'bold'}}>
            {assistance.row.original.user && assistance.row.original.user.profile ?
              assistance.row.original.user.profile.first_name +" "+ assistance.row.original.user.profile.last_name
              :null
            } 
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("QUESTIONS"),
      accessor:"label",
      Cell: (assistance) => (
        <>
          <div className="d-flex align-items-center" style={{width:200,height:50,overflow:"clip",verticalAlign:"middle"}}>
            
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <p>
                  {assistance.row.original.content_text}
                </p>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("ENVOYE LE "),
      Cell: (assistance) => (
        <>
          <div className="d-flex align-items-center" >
            
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <p>
                  {moment(assistance.row.original.request_date).format('DD-MM-YYYY')}
                </p>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("REPONSES"),
      Cell: (assistance) => (
        <>
          <div className="d-flex align-items-center" >
            
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1" style={{textAlign:'center'}}>
                <p>
                  {Response(assistance.row.original.id)}
                </p>
              </h5>
            </div>
          </div>
        </>
      ),
    },
   
    {
      Header: "ACTIONS",
      Cell: (cellProps) => {
        const assistancesData = cellProps.row.original;
        return(
          <>
            <li className="list-inline-item" title={props.t("Répondre")}>
              {
              <div className="btn btn-soft-warning" >
              <Link to={`apps-assistances-add/${assistancesData.id}/`}>
                Répondre
              </Link>
              </div>
              }                
            </li>
            <li className="list-inline-item" title={props.t("Voir")}>
              <Link to={`apps-assistances-detail/${assistancesData.id}/`} className="text-warning d-inline-block"
                    >
                  <i className="ri-eye-fill align-bottom"></i>
              </Link>
           </li>
          </> 
        );
      },
    },
    
  ],
    [props,Response]
  );


  document.title = props.t("Assitance technique");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={props.t("Assistance technique")} pageTitle={props.t("Assistance technique")} />
        <Row>
          <div className="col-xl-12 col-lg-12">
            <div>
              <div className="card">
                <div className="card-body pt-0">
                  {  isLoading ? ( // If loading, show loading indicator
                      <div className="py-4 text-center">
                        <div>
                          <lord-icon
                              src="https://cdn.lordicon.com/xjovhxra.json"
                              trigger="loop"
                              colors="primary:#0960b6,secondary:#02376b"
                              style={{ width: "72px", height:"72px" }}>
                          </lord-icon>
                        </div>
                        <div className="mt-4">
                          <h5>{props.t("Chargement")}...</h5>
                        </div>
                      </div>
                    ) : assistances && assistances.length > 0  ? (
                    <div>
                    <TableContainer
                      columns={columns}
                      data={(assistances|| [])}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      divClass="table-responsive mb-1"
                      tableClass="mb-0 align-middle "
                      theadClass="table-light text-muted"
                    />
                    </div>
                    ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5>{props.t("Désolé, pas de resultats trouvés")}</h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};
Assistances.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(Assistances))