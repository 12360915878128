import axios from "axios";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { registerPlugin } from "react-filepond";
import { withTranslation } from "react-i18next";
import "react-image-gallery/styles/css/image-gallery.css";
import PhoneInputWithCountrySelect, {
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/mobile";
import "react-phone-number-input/style.css";
import { Link, useHistory, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { object, ref, string } from "yup";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  API_URL,
  confirmPasswordInvalid,
  DataUserType,
  emailInvalid,
  passwordInvalid,
  phoneNumberInvalid,
  requiredString,
} from "../../../Components/constants/all";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const AddTeamMember = (props) => {
  document.title = "Ajout d'un utilisateur";
  const history = useHistory();

  const token = JSON.parse(sessionStorage.getItem("authUser"))
    ? JSON.parse(sessionStorage.getItem("authUser")).access
    : null;

  if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      username: "",
      phone_number: "",
      email: "",
      password: "",
      confirmPassword: "",
      user_type: "",
    },

    validationSchema: object().shape({
      username: string().required(requiredString),
      phone_number: string()
        .test(
          "isValidPhoneNumber",
          phoneNumberInvalid,
          (value) => value && isPossiblePhoneNumber(value)
        )
        .required(requiredString),
      email: string().email(emailInvalid).required(requiredString),
      password: string()
        .required(requiredString)
        .min(8, passwordInvalid.first)
        .matches(/[A-Z]/, passwordInvalid.second)
        .matches(/[a-z]/, passwordInvalid.third)
        .matches(/\d/, passwordInvalid.fourth)
        .matches(/[@$!%*?&]/, passwordInvalid.fifth),
      confirmPassword: string()
        .oneOf([ref("password"), null], confirmPasswordInvalid)
        .required(requiredString),
      user_type: string().required(requiredString),
    }),
    onSubmit: async (values) => {
      try {
        const res = await axios.post(`${API_URL}/users/`, values);
        if (res.status === 201) {
          toast.success(props.t("Utilisateur bien ajouté"), {
            autoClose: 3000,
          });
        }
        validation.resetForm();
        history.push("pages-team");
        setTimeout(() => window.location.reload(), 3000);
      } catch (error) {
        console.error(error.response || error);
        toast.error(
          props.t(
            "Utilisateur non ajouté, Vérifier votre connexion et réessayer"
          ),
          { autoClose: 3000 }
        );
      }
    },
  });

  const handleSubmit = async (values) => {};

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t("Ajouter un utilisateur")}
          pageTitle={props.t("Utilisateurs")}
        />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
          }}
        >
          <Row>
            <Col lg={12}>
              <Card className="p-4">
                <div className="mb-3">
                  <Label className="form-label" htmlFor="username">
                    {props.t("Nom d'utilisateur")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form"
                    name="username"
                    autoComplete="off"
                    value={validation.values.username}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    invalid={
                      validation.errors.username && validation.touched.username
                        ? true
                        : false
                    }
                  />
                  {validation.errors.username && validation.touched.username ? (
                    <FormFeedback type="invalid">
                      {props.t(validation.errors.username)}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="phone_number">
                    {props.t("Téléphone")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <PhoneInputWithCountrySelect
                    defaultCountry="US"
                    international
                    placeholder="Téléphone"
                    id="phone_number"
                    name="phone_number"
                    className={`form-control ${
                      validation.errors.phone_number &&
                      validation.touched.phone_number
                        ? "border-danger"
                        : "border-success"
                    }`}
                    value={validation.values.phone_number || ""}
                    onChange={(value) => {
                      validation.setFieldValue("phone_number", value || "");
                      if (value && isPossiblePhoneNumber(value)) {
                        validation.setFieldError("phone_number", undefined);
                      } else {
                        validation.setFieldError(
                          "phone_number",
                          "Numéro de téléphone invalide"
                        );
                      }
                    }}
                  />
                  {validation.errors.phone_number &&
                  validation.touched.phone_number ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phone_number}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="email">
                    {props.t("Email")} <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form"
                    autoComplete="off"
                    name="email"
                    value={validation.values.email}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    invalid={
                      validation.errors.email && validation.touched.email
                        ? true
                        : false
                    }
                  />
                  {validation.errors.email && validation.touched.email ? (
                    <FormFeedback type="invalid">
                      {props.t(validation.errors.email)}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-2">
                  <Label
                    for="choices-payment-type"
                    className="form-label text-muted text-uppercase fw-semibold"
                  >
                    {props.t("Type d'Utilisateur")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <div className="input-light">
                    <select
                      className="form-control"
                      data-choices
                      data-choices-search-false
                      name="user_type"
                      id="choices-single-default"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                    >
                      <option defaultValue="">
                        {props.t("Sélectionner le type d'utilisateur")}
                      </option>
                      {DataUserType.map((item, index) => {
                        return (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {validation.errors.user_type &&
                  validation.touched.user_type ? (
                    <FormFeedback type="invalid">
                      {props.t(validation.errors.user_type)}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="password">
                    {props.t("Mot de passe")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="password"
                    autoComplete="off"
                    className="form"
                    name="password"
                    value={validation.values.password}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    invalid={
                      validation.errors.password && validation.touched.password
                        ? true
                        : false
                    }
                  />
                  {validation.errors.password && validation.touched.password ? (
                    <FormFeedback type="invalid">
                      {props.t(validation.errors.password)}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="confirmPassword">
                    {props.t("Confirmer le mot de passe")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="password"
                    autoComplete="off"
                    className="form"
                    name="confirmPassword"
                    value={validation.values.confirmPassword}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    invalid={
                      validation.errors.confirmPassword &&
                      validation.touched.confirmPassword
                        ? true
                        : false
                    }
                  />
                  {validation.errors.confirmPassword &&
                  validation.touched.confirmPassword ? (
                    <FormFeedback type="invalid">
                      {props.t(validation.errors.confirmPassword)}
                    </FormFeedback>
                  ) : null}
                </div>
              </Card>
              <div className="text-end mb-3">
                <button className="btn btn-primary mr-6">
                  <Link to={`/pages-team`} className="text-white">
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning m-3">
                  {props.t("Ajouter")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};
AddTeamMember.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(AddTeamMember));
