import React, { useEffect, useState} from "react";
import { useSelector} from "react-redux";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import axios from 'axios';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import {
  Card,
  Col,
  Container,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";

// Redux
import { useDispatch } from "react-redux";
import {getProductsCategories as onGetCategories } from "../../store/ecommerce/action";
import { Link, useHistory, useParams } from "react-router-dom";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const FormationsUpdate=(props)=>{
  document.title = "Modifier une formation";
  const {categories } = useSelector((state) => ({
    categories :state.Ecommerce.categories
  
  }));

  const formationId = useParams('id')

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };



  const history = useHistory();
  const dispatch = useDispatch();
 
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;

  // envoyer products s'il n'esxiste pas
  useEffect(() => {
    if (categories && !categories.length) {
      dispatch(onGetCategories());
    }
  }, [dispatch, categories]);


  const url = "https://prod.leconseillerdubtp.com/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const [selectFormations, setSelectFormations] = useState({})
  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get(`${url}/formations/${formationId.id}/`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setSelectFormations(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

  const formationMode = [
    {
      label: "En ligne",
      value: "online",
    },
    {
      label: "En Presentiel",
      value: "on-site",
    },
  ];
    
    const validation = useFormik({
      enableReinitialize: true,
      initialValues: {
        "title": selectFormations.title,
        "description": selectFormations.description,
        "start_date": selectFormations.start_date,
        "end_date": selectFormations.end_date,
        "registration_start": selectFormations.registration_start,
        "registration_end": selectFormations.registration_end,
        "formation_file":selectFormations.formation_file,
        "formation_link": selectFormations.formation_link,
        "published": selectFormations.published,
        "domain": selectFormations.domain,
        "audiance": selectFormations.audiance,
        "formateur": selectFormations.formateur,
        "lieu": selectFormations.lieu,
        "mode": selectFormations.mode,
    },

      validationSchema: Yup.object().shape({
        "title": Yup.string().required('Ce champ est requis'),
        "description": Yup.string().required('Ce champ est requis'),
        "domain": Yup.string().required('Ce champ est requis'),
        "audiance": Yup.string().required('Ce champ est requis'),
        "formateur": Yup.string().required('Ce champ est requis'),
        "lieu": Yup.string().when('mode', {
          is: 'on-site',
          then: Yup.string().required('Ce champ est requis'),
          otherwise: Yup.string().notRequired(),
        }),
        "formation_link": Yup.string().required('Ce champ est requis'),
        "mode": Yup.string().required('Ce champ est requis')
       }),

      onSubmit: (values) => {
            const formData= new FormData();
            formData.append("title",values.title);
            formData.append("domain",values.domain);
            formData.append("formateur",values.formateur)
            formData.append("mode",values.mode)
            formData.append("lieu",values.lieu)
            formData.append("description",values.description);
            formData.append("audiance",values.audiance)
            formData.append("start_date",values.start_date);
            formData.append("end_date",values.end_date);
            formData.append("registration_start",values.registration_start);
            formData.append("registration_end",values.registration_end);
            formData.append("formation_link",values.formation_link);
            selectedFile && formData.append("formation_file",selectedFile);
            axios.patch(`${url}/formations/${formationId.id}/`,formData)
              .then(res =>{
                if(res){
                  toast.success(props.t("Formation bien modifiée"),{autoClose:3000})
                }
              }, (error) =>{
                if(error){
                  toast.error(props.t("Formation non modifiée"),{autoClose:3000})
                }}
              )
              validation.resetForm();
              history.push("/apps-formations"); 
              setTimeout(()=>window.location.reload(true),2000); 

        } 
           
    });

    return (
      <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.t("Modification d'une formation")} pageTitle={props.t("Formations")} />
        <Form encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit(); 
              }}
              >
          <Row>
            <Col lg={12}>
              <Card className="p-4">
                    <Row>
                      <Col lg={6}> 
                        <div className="mb-3" >
                          <Label className="form-label" htmlFor="photo">
                            {props.t("IMAGE")}   <span style={{color: "red"}}>* Tous les champs sont obligatoires</span>
                          </Label>
                            <div>
                                  <input
                                  type="file"
                                  className="form-control" 
                                  name="formation_file" 
                                  accept="image/jpg" 
                                  onChange={handleFileChange}/>
                                  <br />
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      {selectedFile ? <img src={URL.createObjectURL(selectedFile)} style={{maxWidth:'50%'}} alt={`Thumbnail`} /> :  (<img src={selectFormations.formation_file} style={{maxWidth:'50%'}} alt={`Thumbnail`} />)}
                                    </div>
                            </div>
                            {validation.errors.formation_file && validation.touched.formation_file? (
                              <FormFeedback type="invalid">{props.t(validation.errors.formation_file)}</FormFeedback>
                            ) : null}
                          <br />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="title">
                            {props.t("TITRE")}  <span style={{color: "red"}}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form"
                            style={{fontWeight:"bold", textTransform:"uppercase"}}
                            name="title"
                            value={validation.values.title}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={validation.errors.title && validation.touched.title ? true : false}
                          />
                          {validation.errors.title && validation.touched.title? (
                            <FormFeedback type="invalid">{props.t(validation.errors.title)}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    {/* </Row>
                    <Row> */}
                      <Col lg={6}> 
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="domain">
                            {props.t("DOMAINE DE LA FORMATION")}  <span style={{color: "red"}}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form"
                            name="domain"
                            value={validation.values.domain}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={validation.errors.domain && validation.touched.domain ? true : false}
                          >
                        </Input>
                            {validation.errors.domain && validation.touched.domain? (
                              <FormFeedback type="invalid">{props.t(validation.errors.domain)}</FormFeedback>
                            ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="formateur">
                          {props.t("FORMATEUR")}  <span style={{color: "red"}}>*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form"
                          name="formateur"
                          value={validation.values.formateur}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={validation.errors.formateur && validation.touched.formateur ? true : false}
                        >
                      </Input>
                        {validation.errors.formateur && validation.touched.formateur? (
                              <FormFeedback type="invalid">{props.t(validation.errors.formateur)}</FormFeedback>
                            ) : null}
                        
                      </div>
                      </Col>
                    {/* </Row>
                    <Row> */}
                      <Col lg={6}> 
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="audiance">
                            {props.t("AUDIENCE")}  <span style={{color: "red"}}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form"
                            name="audiance"
                            value={validation.values.audiance}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={validation.errors.audiance && validation.touched.audiance ? true : false}
                          >
                          
                        </Input>
                            {validation.errors.audiance && validation.touched.audiance ? (
                              <FormFeedback type="invalid">{props.t(validation.errors.audiance )}</FormFeedback>
                            ) : null}
                        
                        </div>
                      </Col>

                      <Col lg={6}> 
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="mode">
                            {props.t("MODE")}  <span style={{color: "red"}}>*</span>
                          </Label>
                          <Input
                            type="select"
                            className="form"
                            name="mode"
                            id="mode"
                            value={validation.values.mode}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={validation.errors.mode && validation.touched.mode ? true : false}
                          >
                            <option value={""}>Sélectionnez un mode</option>
                            {
                              formationMode.map((item, index) => (
                                <>
                                <option value={item.value} key={index} selected={item.value === selectFormations.mode ? true : false} > { item.label } </option>
                                </>
                              ))
                            }
                          </Input>
                            {validation.errors.mode && validation.touched.mode ? (
                              <FormFeedback type="invalid">{props.t(validation.errors.mode )}</FormFeedback>
                            ) : null}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="LIEU">
                            {props.t("LIEU DE LA FORMATION")}  { validation.values.mode === "on-site" ? <span style={{color: "red"}}>*</span> : null}
                          </Label>
                          <Input
                            type="text"
                            className="form"
                            name="lieu"
                            value={validation.values.lieu}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={validation.errors.lieu && validation.touched.lieu ? true : false}
                          >
                        </Input>
                          {validation.errors.lieu && validation.touched.lieu? (
                              <FormFeedback type="invalid">{props.t(validation.errors.lieu)}</FormFeedback>
                            ) : null}
                        
                        </div>
                      </Col>
                    {/* </Row>

                    <Row> */}
                      <Col lg={6}> 
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="">
                            {props.t("DATE DE DEBUT")}  <span style={{color: "red"}}>*</span>
                          </Label>
                          <Input
                            type="date"
                            className="form"
                            name="start_date"
                            value={validation.values.start_date}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={validation.errors.start_date && validation.touched.start_date ? true : false}
                          >
                          
                        </Input>
                        {validation.errors.start_date && validation.touched.start_date ? (
                              <FormFeedback type="invalid">{props.t(validation.errors.start_date )}</FormFeedback>
                            ) : null}
                        
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="">
                            {props.t("DATE DE FIN")}  <span style={{color: "red"}}>*</span>
                          </Label>
                          <Input
                            type="date"
                            className="form"
                            name="end_date"
                            value={validation.values.end_date}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={validation.errors.end_date && validation.touched.end_date ? true : false}
                          >
                          
                        </Input>
                        {validation.errors.end_date && validation.touched.end_date ? (
                              <FormFeedback type="invalid">{props.t(validation.errors.end_date )}</FormFeedback>
                            ) : null}
                        
                        </div>
                      </Col>
                    {/* </Row>

                    
                    <Row> */}
                      <Col lg={6}> 
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="">
                            {props.t("DATE DE SOUMISSION")}  <span style={{color: "red"}}>*</span>
                          </Label>
                          <Input
                            type="date"
                            className="form"
                            name="registration_start"
                            value={validation.values.registration_start}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={validation.errors.registration_start && validation.touched.registration_start ? true : false}
                          >
                          
                        </Input>
                          {validation.errors.registration_start && validation.touched.registration_start? (
                            <FormFeedback type="invalid">{props.t(validation.errors.registration_start)}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="">
                          {props.t("ECHEANCE")}  <span style={{color: "red"}}>*</span>
                        </Label>
                        <Input
                          type="date"
                          className="form"
                          name="registration_end"
                          value={validation.values.registration_end}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={validation.errors.registration_end && validation.touched.registration_end ? true : false}
                        >
                        
                      </Input>
                        {validation.errors.registration_end && validation.touched.registration_end? (
                          <FormFeedback type="invalid">{props.t(validation.errors.registration_end)}</FormFeedback>
                        ) : null}
                      </div>
                      </Col>
                    </Row>
                    
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="publication_link">
                          {props.t("LIEN")}  <span style={{color: "red", borderTop:'10px'}}>* (Format du lien : https://www.example.com)</span>
                        </Label>
                        <Input
                          type="url"
                          className="form"
                          placeholder={props.t("Entrer l'url de l'article")}
                          name="formation_link"
                          value={validation.values.formation_link}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={validation.errors.formation_link && validation.touched.formation_link ? true : false}
                        />
                        {validation.errors.formation_link && validation.touched.formation_link ? (
                          <FormFeedback type="invalid">{props.t(validation.errors.formation_link)}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                          <Label className="form-label" htmlFor="description"> {props.t("Description")}  <span style={{color: "red"}}>*</span></Label>
                          <textarea
                          className="form-control"
                          name="description"
                          value={validation.values.description}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          rows="5"
                          cols="20"
                          >
                          {validation.errors.description && validation.touched.description? (
                            <FormFeedback type="invalid">{props.t(validation.errors.description)}</FormFeedback>
                          ) : null}
                          </textarea>
                          
                      </div>
                </Card>
                <div className="text-end mb-3">
                  <button className="btn btn-primary mr-6">
                    <Link
                      to={`/apps-formations`}
                      className="text-white"
                    >
                      {props.t("Retour")}
                    </Link>
                  </button>
                  <button type="submit" className="btn btn-warning m-2">
                    {props.t("Modifier")}
                  </button>
                </div> 
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
    );
};
FormationsUpdate.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(FormationsUpdate));
