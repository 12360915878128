import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";

const AudiencesCharts = ({ dataColors, series }) => {
  const url = "https://prod.leconseillerdubtp.com/api/v1/";
  const token = JSON.parse(sessionStorage.getItem("authUser"))
    ? JSON.parse(sessionStorage.getItem("authUser")).access
    : null;

  if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const [newTask, setNewTask] = useState([]);
  const [TaskList, setTaskList] = useState([]);
  const [TaskListInterest, setTaskListInterest] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await axios.get(`${url}/sondages/`);

        const aggregatedData = data?.reduce(
          (acc, item) => {
            const existingItem = acc.find((i) => i.interet === item.interet);
            if (existingItem) {
              existingItem.interest_level += item.interest_level; // Ajoute la valeur de interest_level
            } else {
              acc.push({ ...item });
            }
            return acc;
          },
          [data]
        );

        setNewTask(aggregatedData); // Mettez à jour newTask avec les données agrégées
        console.log(aggregatedData);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [url]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await axios.get(`${url}/users/list/`);
        setTaskList(data);
        console.log(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [url]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await axios.get(`${url}/categories/`);
        setTaskListInterest(data);
        console.log(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [url]);

  const sondages = useMemo(() => {
    return newTask.filter((element) => !element?.[0]);
  }, [newTask]);

  const interest = (j) => {
    for (let i = 0; i <= TaskListInterest?.length; i++) {
      if (j === TaskListInterest[i]?.id) {
        const interet = TaskListInterest[i]?.designation;
        return interet;
      }
    }
  };

  const CategoriesData = useMemo(() => {
    const data = sondages
      .filter((element) => element?.interet && element?.interet !== "undefined")
      .map((element) => ({
        name: interest(element?.interet),
      }));

    console.log(data);

    const categories = data.map((element) => element?.name);

    console.log(categories);

    return categories;
  }, [sondages]);

  console.log(CategoriesData);

  var chartAudienceColumnChartsColors = getChartColorsArray(dataColors);
  var options = {
    chart: {
      type: "area",
      height: 309,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      fontWeight: 400,
      fontSize: "8px",
      offsetX: 0,
      offsetY: 0,
      markers: {
        width: 9,
        height: 9,
        radius: 4,
      },
    },
    stroke: {
      show: true,
      width: 2,
      curve: "smooth", // Ajout de courbes lisses pour le type "area"
    },
    grid: {
      show: false,
    },
    colors: chartAudienceColumnChartsColors,
    xaxis: {
      categories: CategoriesData,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        strokeDashArray: 1,
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      show: false,
    },
    fill: {
      opacity: 1,
      type: "gradient", // Ajout d'un dégradé pour le type "area"
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height="439"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const AudiencesSessionsCharts = ({ dataColors, series }) => {
  var chartHeatMapBasicColors = getChartColorsArray(dataColors);

  var options = {
    chart: {
      height: 400,
      type: "heatmap",
      offsetX: 0,
      offsetY: -8,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 50,
              color: chartHeatMapBasicColors[0],
            },
            {
              from: 51,
              to: 100,
              color: chartHeatMapBasicColors[1],
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      horizontalAlign: "center",
      offsetX: 0,
      offsetY: 20,
      markers: {
        width: 20,
        height: 6,
        radius: 2,
      },
      itemMargin: {
        horizontal: 12,
        vertical: 0,
      },
    },
    colors: chartHeatMapBasicColors,
    tooltip: {
      y: [
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + "k";
            }
            return y;
          },
        },
      ],
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="heatmap"
        height="400"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

// const CountriesCharts = ({ dataColors, series }) => {
//     var barchartCountriesColors = getChartColorsArray(dataColors);
//     var options = {
//         chart: {
//             type: 'bar',
//             height: 436,
//             toolbar: {
//                 show: false,
//             }
//         },
//         plotOptions: {
//             bar: {
//                 borderRadius: 4,
//                 horizontal: true,
//                 distributed: true,
//                 dataLabels: {
//                     position: 'top',
//                 },
//             }
//         },
//         colors: barchartCountriesColors,
//         dataLabels: {
//             enabled: true,
//             offsetX: 32,
//             style: {
//                 fontSize: '12px',
//                 fontWeight: 400,
//                 colors: ['#adb5bd']
//             }
//         },

//         legend: {
//             show: false,
//         },
//         grid: {
//             show: false,
//         },
//         xaxis: {
//             categories: ['Admin', 'Commite', 'Experts', 'Professionnels', 'Organisations', 'Entreprises','Particuliers'],
//         },
//         series:series
//     };
//     return (
//         <React.Fragment>
//             <ReactApexChart
//                 options={options}
//                 series={series}
//                 type="bar"
//                 height="436"
//                 className="apex-charts"
//             />
//         </React.Fragment>
//     );
// };

const CountriesCharts = ({ dataColors, series }) => {
  var barchartCountriesColors = getChartColorsArray(dataColors);
  var options = {
    chart: {
      type: "bar",
      height: 436,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false, // Modification ici pour afficher les barres verticalement
        distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: barchartCountriesColors,
    dataLabels: {
      enabled: true,
      offsetX: 32,
      style: {
        fontSize: "12px",
        fontWeight: 400,
        colors: ["white"],
      },
    },

    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: [
        "Admin",
        "Commite",
        "Experts",
        "Professionnels",
        "Organisations",
        "Entreprises",
        "Particuliers",
      ],
    },
    series: series,
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="436"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const UsersByDeviceCharts = ({ dataColors, series }) => {
  var dountchartUserDeviceColors = getChartColorsArray(dataColors);
  const options = {
    labels: ["Desktop", "Mobile", "Tablet"],
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        size: 100,
        donut: {
          size: "76%",
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    series: series,
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 0,
      offsetY: 0,
      markers: {
        width: 20,
        height: 6,
        radius: 6,
      },
      itemMargin: {
        horizontal: 12,
        vertical: 0,
      },
    },
    stroke: {
      width: 12,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value + "k Users";
        },
      },
      tickAmount: 4,
      min: 0,
    },
    colors: dountchartUserDeviceColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height="219"
      />
    </React.Fragment>
  );
};

export {
  AudiencesCharts,
  AudiencesSessionsCharts,
  CountriesCharts,
  UsersByDeviceCharts,
};
