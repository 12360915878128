import React, { useEffect, useState} from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useSelector} from "react-redux";
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-image-gallery/styles/css/image-gallery.css';

import {
  Card,
  Col,
  Container,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";

// Redux
import { useDispatch } from "react-redux";

import { Link, useHistory } from "react-router-dom";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const CollaborateursUpdate = (props) => {
 
  document.title = "Modification d'un collaborateur";
  const history = useHistory();
  const dispatch = useDispatch();
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };





  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const dateFormat = () => {
    let d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let h = (d.getHours() % 12) || 12;
    let ampm = d.getHours() < 12 ? "AM" : "PM";
    return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear() + ", " + h + ":" + d.getMinutes() + " " + ampm).toString());
  };

  const [date, setDate] = useState(dateFormat());

  const dateformate = (e) => {
    const dateString = e.toString().split(" ");
    let time = dateString[4];
    let H = +time.substr(0, 2);
    let h = (H % 12) || 12;
    h = (h <= 9) ? h = ("0" + h) : h;
    let ampm = H < 12 ? "AM" : "PM";
    time = h + time.substr(2, 3) + " " + ampm;

    const date = dateString[2] + " " + dateString[1] + ", " + dateString[3];
    const orderDate = (date + ", " + time).toString();
    setDate(orderDate);
  };

  
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const url = "https://prod.leconseillerdubtp.com/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    const categgory = [
      {label:"Commité", value:"commite"},
      {label:"Assistant", value:"assistant"},
      {label:"Admin", value:"admin"},
    ];

    const {id} = useParams('id')

  
    const [collaborators,setCollaborators]=useState({});
    useEffect(() => {
        const fetchData = async () => {
          try {
            const data  = await axios.get(`${url}/collaborators/${id}/`);
            setCollaborators(data)
            console.log(data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
  
      }, [url]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      "username": collaborators && collaborators.username,
      "email": collaborators && collaborators.email,
      "phone_number": collaborators && collaborators.phone_number,
      "type_collab": collaborators && collaborators.profile && collaborators.profile.user_type
  },
    
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Le champ est requis"),
      email:Yup.string().required("Le champ est requis"),
      type_collab:Yup.string().required("Le champ est requis")
     }),
    onSubmit: (values) => {

     const newCollaborator={
      "username": collaborators && collaborators.username,
      "email": collaborators && collaborators.email,
      "phone_number": collaborators && collaborators.phone_number,
      "type_collab":values.type_collab
      }

      axios.patch(`${url}/collaborators/${id}/`,newCollaborator)
      .then(res =>{
        if(res){
          toast.success(props.t("Collaborateur bien modifié"),{autoClose:3000})
        }
      }, (error) =>{
        if(error){
          toast.error(props.t("Le numéro de téléphone, l'email ou l'identifiant existe deja. Le numéro ne doit pas dépasser 10 chiffres."),{autoClose:5000})
        }}
      )
      validation.resetForm();
      history.push("/apps-collaborateurs");
      setTimeout(()=>window.location.reload(true),3000);
    }
  })
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.t("Modification de collaborateur")} pageTitle={props.t("Utilisateurs")} />
        <Form encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit(); 
              }}
              >
        <Row>
          <Col lg={12}>
            <Card className="p-4">
                 
                
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="title">
                        {props.t("IDENTIFIANT")}  <span style={{color: "red"}}>*</span>
                      </Label>
                      <Input
                        type="username"
                        className="form"
                        style={{fontWeight:"bold"}}
                        name="username"
                        value={validation.values.username}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        invalid={validation.errors.username && validation.touched.username ? true : false}
                        readOnly
                      />
                      {validation.errors.username && validation.touched.username? (
                        <FormFeedback type="invalid">{props.t(validation.errors.username)}</FormFeedback>
                      ) : null}
                    </div>
                
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="phone_number">
                        {props.t("TEL")}  <span style={{color: "red"}}>*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        name="phone_number"
                        value={validation.values.phone_number}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        invalid={validation.errors.phone_number && validation.touched.phone_number ? true : false}
                        readOnly
                      >
                    </Input>
                      {validation.errors.phone_number && validation.touched.phone_number? (
                        <FormFeedback type="invalid">{props.t(validation.errors.phone_number)}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label" htmlFor="email">
                        {props.t("EMAIL")}  <span style={{color: "red"}}>*</span>
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        name="email"
                        value={validation.values.email}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        invalid={validation.errors.email && validation.touched.email ? true : false}
                        readOnly
                      >
                    </Input>
                      {validation.errors.email && validation.touched.email? (
                        <FormFeedback type="invalid">{props.t(validation.errors.email)}</FormFeedback>
                      ) : null}
                    </div>
                    
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="type_collab">
                        {props.t("TYPE D'UTILISATEUR")}  <span style={{color: "red"}}>*</span>
                      </Label>
                      <Input
                        type="select"
                        className="form-select"
                        name="type_collab"
                        value={validation.values.type_collab}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        invalid={validation.errors.type_collab && validation.touched.type_collab ? true : false}
                      
                      >
                         <option>Sélectionner une catégorie</option>
                        {categgory.map((item,key)=> (
                            <option value={item.value} key={key}>{item.label}</option>
                        ))
                        }
                    </Input>
                    
                    </div>
            </Card>
              <div className="text-end mb-3">
                <button className="btn btn-primary mr-6">
                  <Link
                    to={`/apps-collaborateurs`}
                    className="text-white"
                  >
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning mx-2">
                   {props.t("Modifier")}
                </button>
              </div> 
          </Col>
        </Row>
   </Form>
      </Container>
    </div>
  );
};
CollaborateursUpdate.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(CollaborateursUpdate));

