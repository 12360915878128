import React, { useEffect, useState} from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-image-gallery/styles/css/image-gallery.css';

import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";

// Redux
import { useDispatch } from "react-redux";

import 'react-phone-number-input/style.css'

import PhoneInputWithCountrySelect, { isPossiblePhoneNumber } from 'react-phone-number-input';

import { Link, useHistory } from "react-router-dom";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const CollaborateursAdd= (props) => {
 
  document.title = "Création d'un collaborateur";
  const history = useHistory();
  const dispatch = useDispatch();
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const [phoneinput,setPhoneinput] = useState();
  const [valid,setValid] = useState();

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const url = "https://prod.leconseillerdubtp.com/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    const categgory = [
      {label:"Commité", value:"commite"},
      {label:"Assistant", value:"assistant"},
      {label:"Admin", value:"admin"},
    ];

  
    const [collaborators, setCollaborators] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const data  = await axios.get(`${url}/users/list/`);
            setCollaborators(data)
            console.log(data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
  
      }, [url]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      "username": "",
      "email": "",
      "phone_number": phoneinput,
      "type_collab": null
  },
    
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Le champ est requis"),
      email:Yup.string().required("Le champ est requis"),
      type_collab:Yup.string().required("Le champ est requis")
     }),
    onSubmit: (values) => {

     const newCollaborator={
        username:values.username,
        phone_number:values.phone_number,
        email:values.email,
        type_collab:values.type_collab
      }

      axios.post(`${url}/collaborators/`,newCollaborator)
      .then(res =>{
        if(res){
          toast.success(props.t("Collaborateur bien ajoutée"),{autoClose:3000})
        }
      }, (error) =>{
        if(error){
          toast.error(props.t("Le numéro de téléphone, l'email ou l'identifiant existe deja. Le numéro ne doit pas dépasser 10 chiffres."),{autoClose:5000})
        }}
      )
      validation.resetForm();
      history.push("/apps-collaborateurs");
      setTimeout(()=>window.location.reload(true),3000);
    }
  })
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.t("Création de collaborateur")} pageTitle={props.t("Utilisateurs")} />
        <Form encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit(); 
              }}
              >
        <Row>
          <Col lg={12}>
            <Card className="p-4">
                 
                
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="title">
                        {props.t("IDENTIFIANT")}  <span style={{color: "red"}}>*</span>
                      </Label>
                      <Input
                        type="username"
                        className="form"
                        style={{fontWeight:"bold"}}
                        name="username"
                        value={validation.values.username}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        invalid={validation.errors.username && validation.touched.username ? true : false}
                      />
                      {validation.errors.username && validation.touched.username? (
                        <FormFeedback type="invalid">{props.t(validation.errors.username)}</FormFeedback>
                      ) : null}
                    </div>
                

                                                        <div className="mb-3">
                                                       
                      <Label className="form-label" htmlFor="title">
                        {props.t("TEL")}  <span style={{color: "red"}}>*</span>
                      </Label>
                                                          
                                                            <PhoneInputWithCountrySelect 
                                                                defaultCountry="US"
                                                                international
                                                                placeholder="Téléphone"
                                                                id="phone_number"
                                                                name="phone_number"
                                                                numberInputProps={{
                                                                    className: `form-control ${!valid ? 'border-danger' : 'border-success'}`
                                                                }}
                                                                value={validation.values.phone_number || ""}
                                                                onChange={(e) => {
                                                                    if(!isNaN(e))
                                                                    {
                                                                        if(isPossiblePhoneNumber(e))
                                                                        {
                                                                            setPhoneinput(e)
                                                                            setValid(true)
                                                                        }
                                                                        else{
                                                                            setPhoneinput(e)
                                                                            setValid(false)
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            <label className={`text-danger ${valid ? 'd-none' : 'd-block'}`}>{valid === false ? 'Numéro de téléphone invalide' : ''}</label>
                                                        </div>

                    <div className="mb-3">
                      <Label className="form-label" htmlFor="email">
                        {props.t("EMAIL")}  <span style={{color: "red"}}>*</span>
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        name="email"
                        value={validation.values.email}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        invalid={validation.errors.email && validation.touched.email ? true : false}
                      >
                    </Input>
                      {validation.errors.email && validation.touched.email? (
                        <FormFeedback type="invalid">{props.t(validation.errors.email)}</FormFeedback>
                      ) : null}
                    </div>
                    
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="type_collab">
                        {props.t("TYPE D'UTILISATEUR")}  <span style={{color: "red"}}>*</span>
                      </Label>
                      <Input
                        type="select"
                        className="form-select"
                        name="type_collab"
                        value={validation.values.type_collab}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        invalid={validation.errors.type_collab && validation.touched.type_collab ? true : false}
                      >
                         <option>Sélectionner une catégorie</option>
                        {categgory.map((item,key)=> (
                            <option value={item.value} key={key}>{item.label}</option>
                        ))
                        }
                    </Input>
                    
                    </div>
            </Card>
              <div className="text-end mb-3">
                <button className="btn btn-primary mr-6">
                  <Link
                    to={`/apps-collaborateurs`}
                    className="text-white"
                  >
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning mx-2">
                   {props.t("Ajouter")}
                </button>
              </div> 
          </Col>
        </Row>
   </Form>
      </Container>
    </div>
  );
};
CollaborateursAdd.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(CollaborateursAdd));

