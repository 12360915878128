import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import { useReducer } from "react";
import Ecommerce from "../../store/ecommerce/reducer";
import { useCallback } from "react";

import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledCollapse,
  Row,
  Card,
  CardHeader,
  Col,
} from "reactstrap";
import classnames from "classnames";

// RangeSlider
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import DeleteModal from "../../Components/Common/DeleteModal";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
//Import data
import { productsData } from "../../common/data";

//Import actions
import { getArticles as onGetArticles} from "../../store/ecommerce/action";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { red } from "@material-ui/core/colors";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";



const AppelOffres = (props) => {

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []);

  const [selectedMulti, setselectedMulti] = useState(null);
  const [appeloffres, setAppelOffres] = useState([]);

  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://prod.leconseillerdubtp.com/api/v1/appel-offres/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setAppelOffres(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);


  function handleMulti(products ,selectedMulti) {
    setselectedMulti(selectedMulti);
  }

  const role = JSON.parse(sessionStorage.getItem("authUser"))?.role.some((role)=>role === "assistant")

  
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  
  const handleDeleteProduct = () => {
    if (appeloffres) {
      setDeleteModal(false);
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      // dispatch(deleteProducts(element.value));
      del.style.display = 'none';
    });
  };

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".entrepriseCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  }
  const columns = useMemo(() => [
    {
      Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
      Cell: (cellProps) => {
        return <input type="checkbox" className="entrepriseCheckBox" value={cellProps.row.original.id} />;
      },
      id: '#',
    },
    {
      Header: props.t("REF"),
      accessor:"reference",
      Cell: (appels) => (
        <>
          <div className="d-flex align-items-center ">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1 italic">
                <Link
                  className="text-dark" 
                >
                  {" "}
                  {appels.row.original.reference}
                </Link>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t("ILLUSTRATION"),
      Cell: (appels) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <img
                  src={appels.row.original.offer_pic}
                  alt=""
                  className="avatar-sm rounded"
                /> 
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t("TITRE"),
      accessor:"title",
      Cell: (appels) => (
        <>
          <div className="d-flex align-items-center ">
            <div className="flex-grow-1" >
              <h5 className="fs-14 mb-1 uppercase" style={{textTransform:'uppercase',fontWeight:'bold'}}>
                {appels.row.original.title}
              </h5> 
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t("DESCRIPTIF"),
      Cell: (appels) => (
        <>
          <div className="d-flex align-items-center" >
            <div className="flex-grow-1" >
              <h5 className="fs-14 mb-1 " style={{width:200,height:50,overflow:"clip",verticalAlign:"middle"}}>
                <Link
                  to={`/#/${appels.row.original.id}/`}
                  className="text-dark"
                >
                </Link>
                  {appels.row.original.description}
              </h5>
              
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t("PUBLIE"),
      accessor:"publication_date",
      Cell: (appels) => (
        <>
          <div className="d-flex align-items-center" >
            <div className="" >
              <h5 className=" mb-1 badge badge-soft-primary" style={{textAlign:"center"}}>
                <Link
                  to={`/#/${appels.row.original.id}/`}
                  className="text-dark "
                >
                </Link>
                {appels.row.original.publication_date}
              </h5>
              
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t("CLOTURE"),
      accessor:"closure_date",
      Cell: (appels) => (
        <>
          <div className="d-flex align-items-center" >
            
            <div className="flex-grow-1" >
              {new Date() >  new Date(appels.row.original.closure_date) ? 
              ( <h5 className="mb-1 badge badge-soft-danger " style={{textAlign:"center"}}>
              <Link
                to={`/#/${appels.row.original.id}/`}
                className="text-dark "
              >
              </Link>
              {appels.row.original.closure_date}
            </h5>):(
               <h5 className="mb-1 badge badge-soft-warning " style={{textAlign:"center"}}>
               <Link
                 to={`/#/${appels.row.original.id}/`}
                 className="text-dark "
               >
               </Link>
               {appels.row.original.closure_date}
             </h5>
            ) }
             
              
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t("STATUT"),
      accessor:"closed",
      Cell: (appels) => (
        <>
          <div className="d-flex align-items-center" >
            
            <div className="flex-grow-1" >
              {new Date() >  new Date(appels.row.original.closure_date) || new Date(appels.row.original.closure_date) === new Date()? 
              ( <h5 className=" mb-1 badge badge-soft-danger " style={{textAlign:"center"}}>
              <Link
                to={`/#/${appels.row.original.id}/`}
                className="text-dark "
              >
              </Link>
              {"Cloturé"}
            </h5>):(
               <h5 className="mb-1 badge badge-soft-success me-1 " style={{textAlign:"center"}}>
               
               <Link
                 to={`/#/${appels.row.original.id}/`}
                 className="text-dark "
               >
               </Link>
               {"En cours"}
             </h5>
            ) }
             
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t("FICHIER"),
      Cell: (appels) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
            {appels.row.original.offer_file? 
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <a className="text-xs rounded" href={appels.row.original.offer_file} title="Télécharger"><i style={{ fontSize: '30px',width:"50%",height:"50%", color:"red" }} className="ri-file-download-fill align-bottom"></i></a>
              </div>
              :
             "-"
            }
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t("STATUT"),
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1" >
              <h5 className="fs-14  p-2">
                <span className="mt-4">
                  {recruitments.row.original.published === false && !role ?
                    <div>
                      <div className="btn btn-soft-success" >
                        <Link to={`apps-appels-offres-details/${recruitments.row.original.id}/`}>
                          Publier
                        </Link>
                      </div>
                    </div> : 
                    <h5 className="mb-1 badge badge-soft-success " style={{fontSize:'10px'}} >
                        Publiée
                    </h5>
                  }
                </span>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: "ACTIONS",
      Cell: (cellProps) => {
        const appelsData = cellProps.row.original;
        return (
          <div>
              <li className="list-inline-item" title={props.t("Modifier")}>
                <Link to={`apps-appels-offres-update/${appelsData.id}/`} className="text-warning d-inline-block">
                    <i className="ri-pencil-fill align-bottom"></i>
                </Link>
              </li>

              <li className="list-inline-item" title={props.t("Voir")}>
                <Link to={`apps-appels-offres-details/${appelsData.id}/`} className="text-warning d-inline-block">
                    <i className="ri-eye-fill"></i>
                </Link>
              </li>
          </div>
        );
      },
    },
    
  ],
    [props]
  );

  document.title = props.t("Liste des appels d'offres");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={props.t("Liste des appels d'offres")} pageTitle={props.t("Appels d'Offres")} />
        <Row>
          <div className="col-xl-12 col-lg-12">
            <div>
              <div className="card">
                <div className="card-body pt-0">
                  {  isLoading ? ( // If loading, show loading indicator
                      <div className="py-4 text-center">
                        <div>
                          <lord-icon
                              src="https://cdn.lordicon.com/xjovhxra.json"
                              trigger="loop"
                              colors="primary:#0960b6,secondary:#02376b"
                              style={{ width: "72px", height:"72px" }}>
                          </lord-icon>
                        </div>
                        <div className="mt-4">
                          <h5>{props.t("Chargement")}...</h5>
                        </div>
                      </div>
                    ) 
                    :
                  appeloffres ? (
                    <TableContainer
                      columns={columns}
                      data={(appeloffres|| [])}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      divClass="table-responsive mb-1"
                      tableClass="mb-0 align-middle "
                      theadClass="table-light text-muted"
                      isAppelsOffresFilter={true}
                    />
                  ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5>{props.t("Désolé, pas de resultats trouvés")}</h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};
AppelOffres.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(AppelOffres))