import React, { useEffect, useState} from "react";
import { useSelector} from "react-redux";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import axios from 'axios';
import { isEmpty } from "lodash";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import {
  Card,
  Col,
  Container,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";

// Redux
import { useDispatch } from "react-redux";
import {getProductsCategories as onGetCategories } from "../../store/ecommerce/action";
import { Link, useHistory, useParams } from "react-router-dom";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const PublicitiesUpdate=(props)=>{
  document.title = "Modifier un article";
  const {categories } = useSelector((state) => ({
    categories :state.Ecommerce.categories
  
  }));

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const articlesId = useParams('id');
  const url = "https://prod.leconseillerdubtp.com/api/v1";
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const history = useHistory();
  const [selectAticle, setSelectArticle] = useState({})
  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get(`${url}/publicity/${articlesId.id}/`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setSelectArticle(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

 
    const validation = useFormik({
      enableReinitialize: true,
      initialValues: {
       id:selectAticle.id,
       publicity_pic:selectAticle.publicity_pic,
       title:selectAticle.title,
       publicity_content:selectAticle.publicity_content,
       priority:selectAticle.priority
      },
         
    validationSchema: Yup.object().shape({
      title:Yup.string().required('Ce champ est requis'),
      publicity_content:Yup.string().required('Ce champ est requis')
   
     }),

      onSubmit: (values) => {
            const formData= new FormData();
            formData.append("id",selectAticle.id);
            formData.append("title",values.title);
            formData.append("publicity_content",values.publicity_content);
            formData.append("priority",values.priority);
            selectedFile && formData.append("publicity_pic",selectedFile);
            axios.patch(`${url}/publicity/${articlesId.id}/`,formData)
            .then(res =>{
              if(res){
                toast.success(props.t("Publicité bien modifiée"),{autoClose:3000})
              }
            }, (error) =>{
              if(error){
                toast.error(props.t("Publicité non  modifiée"),{autoClose:3000})
              }}
            )
            validation.resetForm();
            history.push("/apps-publicities");
            // setTimeout(()=>window.location.reload(true),2000); 
        } 
           
    });

    return (
      <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.t("Modification de publicité")} pageTitle={props.t("Publicité")} />
        <Form encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit(); 
              }}
        >
        <Row>
          <Col lg={12}>
            <Card className="p-4">
            <div className="mb-3" >
                      <Label className="form-label" htmlFor="photo">
                        {props.t("IMAGE")}   <span style={{color: "red"}}>* Tous les champs sont obligatoires</span>
                      </Label>
                          <div>
                              <input type="file"
                               className="form-control" 
                               name="publicity_pic" 
                               capture="file"
                               accept="image/jpeg" 
                               onChange={handleFileChange}/>
                               <br />
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                  {selectAticle && selectAticle.publicity_pic  ? <img src={selectAticle.publicity_pic} style={{maxWidth:'50%'}} /> : selectAticle.publicity_pic && <img src={selectAticle.publicity_pic} style={{maxWidth:'50%'}}  />}
                                </div>
                          </div>
                      {validation.errors.publicity_pic && validation.touched.publicity_pic? (
                        <FormFeedback type="invalid">{props.t(validation.errors.publicity_pic)}</FormFeedback>
                      ) : null}
                      <br />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="title">
                        {props.t("TITRE")}  <span style={{color: "red"}}>*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form"
                        style={{fontWeight:"bold", textTransform:"uppercase"}}
                        name="title"
                        value={validation.values.title}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        invalid={validation.errors.title && validation.touched.title ? true : false}
                      />
                      {validation.errors.title && validation.touched.title? (
                        <FormFeedback type="invalid">{props.t(validation.errors.title)}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label" htmlFor="publicty_content"> {props.t("CONTENU")}  <span style={{color: "red"}}>*</span></Label>
                      <textarea
                       className="form-control"
                       name="publicity_content"
                       value={validation.values.publicity_content}
                       onBlur={validation.handleBlur}
                       onChange={validation.handleChange}
                       rows="10"
                       cols="50"
                      />
                       {validation.errors.publicity_content && validation.touched.publicity_content? (
                        <FormFeedback type="invalid">{props.t(validation.errors.publicity_content)}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label" htmlFor="priority">
                        {props.t("PRIORITE DE LA PUBLICITE")}  
                      </Label>
                      <Input
                        type="number"
                        className="form"
                        name="priority"
                        value={validation.values.priority}
                        onChange={validation.handleChange}
                        min={1}
                        max={5}
                      />
                      {/* <div style={{ display: 'flex', alignItems: 'center',justifyItems:'center' }}>
                      <button type="button" onClick={()=> setCount(selectAticle.priority - 1) } style={{ marginRight:'20px' }}>-</button>
                      <Input
                        type="number"
                        className="form"
                        name="priority"
                        style={{ textAlign: 'center' }}
                        value={count}
                        onChange={(e) => {
                          const newValue = parseInt(e.target.value);
                            setCount(newValue);
                        }}
                       
                      />
                       <button type="button" onClick={()=> setCount(selectAticle.priority + 1)} style={{ marginLeft:'20px' }}>+</button>
                       </div> */}
                    </div>

            </Card>
              <div className="text-end mb-3">
                <button className="btn btn-primary mr-6">
                  <Link
                    to={`/apps-publicities`}
                    className="text-white"
                  >
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning m-2">
                   {props.t("Modifier")}
                </button>
              </div> 
          </Col>
        </Row>
   </Form>
      </Container>
    </div>
    );
};
PublicitiesUpdate.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(PublicitiesUpdate));
