import React, { useEffect, useState} from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Form
} from "reactstrap";

import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import classnames from "classnames";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import axios from 'axios';

import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import { Link,useHistory, useParams } from "react-router-dom";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

function PublicitiesDetail(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const articlesId = useParams('id');
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;

  const [selectCategory, setSelectCategory] = useState([]);
  const [selectAticle, setSelectArticle] = useState({})
  const url = "https://prod.leconseillerdubtp.com/api/v1";


  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get(`${url}/categories/`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setSelectCategory(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);


  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get(`${url}/publicity/${articlesId.id}/`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setSelectArticle(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);


  const categgory =(j)=>{
    for(let i=0;i<=selectCategory.length;i++){
      if(j===selectCategory[i]?.id){
        return selectCategory[i]?.designation;
      }
    }
  }

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
     published:false
    },

    validationSchema: Yup.object({
        published: Yup.boolean(),
      }),

    onSubmit: (values) => {
        const validatedObject={
            id:selectAticle.id,
            published:true
            }
            axios.patch(`${url}/publicity/${articlesId.id}/`,validatedObject)
            .then(res => {
            if(res){
              toast.success("Publicité validée avec succes",{autoClose:3000})
              history.push("/apps-publicities");
            } 
          })
         
          history.push("/apps-publicities"); 
          validation.resetForm();
          setTimeout(()=>window.location.reload(true),2000); 
      } 
         
  });

document.title =props.t("Détail d'une publicité");
  return (
    <div className="page-content">
      <Container fluid>        
        <BreadCrumb title={props.t("Détail d'une publicité")} pageTitle={props.t("Publicités")} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="gx-lg-5">
                  
                  <Col xl={12}>
                  <div className="mt-xl-0 mt-5">
                    <Row>
                      <Col lg={6} >
                          <img
                                  src={selectAticle.publicity_pic}
                                  className="rounded" style={{width:'100%'}}
                              />
                          
                      </Col>
                      <Col lg={6}  >
                        <div className="product-content ">
                            <Nav tabs className="nav-tabs-custom nav-warning">
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: customActiveTab === "1",
                                  })}
                                  onClick={() => {
                                    toggleCustom("1");
                                  }}
                                >
                                { props.t("Détails")}
                                </NavLink>
                              </NavItem>
                            </Nav>

                            <TabContent
                            activeTab={customActiveTab}
                              className="border border-top-0 p-4"
                              id="nav-tabContent"
                            >
                              <TabPane
                                id="nav-speci"
                                tabId="1"
                              >
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <tbody>
                                      <tr>
                                        <th scope="row"> {props.t("Statut")}</th>
                                        <td className="text-blue-500" style={{color:"blue"}}>{!selectAticle.published ?
                                          <div className="fs-12 uppercase badge badge-soft-danger" 
                                            style={{textAlign:'center',fontSize:'13px'}}> En attente
                                          </div> : <div 
                                            className="fs-12 uppercase badge badge-soft-success" 
                                            style={{textAlign:'center',fontSize:'13px'}}> Validé
                                            </div>
                                        }</td>
                                      </tr>
                                     

                                      <tr>
                                        <th scope="row"> {props.t("Auteur")} </th>
                                        <td className="text-red-500" style={{fontStyle:'italic'}}>{selectAticle.user && selectAticle.user.username}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </TabPane>
                            </TabContent>
                          </div>
                      </Col>
                    </Row>
                      <div className="d-flex">
                        <div className="flex-wrap gap-3">
                          <br></br>
                            <h2> {selectAticle.title}</h2>
                            
                          </div>
                      </div>
                      <div className="mt-4 text-muted" style={{textAlign:'justify'}}>
                        <p>
                          {selectAticle.publicity_content}
                        </p>
                      </div>

                     
                  </div>
                  </Col>
                </Row>
                {
                   !selectAticle.published ?
                   <Form encType="multipart/form-data"
                   onSubmit={(e) => {
                       e.preventDefault();
                       validation.handleSubmit(); 
                   }}
                   >
                   <br></br>
                   <input
                       type="checkbox"
                       name="published"
                       className="form"
                       style={{fontWeight:"30px",width:"20px",height:"20px",marginRight:"5px"}}
                       onChange={validation.handleChange}
                   />
                   <b> J'ai vérifié cette publicité et je confirme son authenticité </b>
                   <div className="text-end mb-3">
                       <button className="btn btn-primary mr-6">
                       <Link
                           to={`/apps-publicities`}
                           className="text-white"
                       >
                           {props.t("Retour")}
                       </Link>
                       </button>
                       <button type="submit" className="btn btn-warning m-2">
                       {props.t("Valider la publicité")}
                       </button>
                   </div>
               </Form>
                : <div className="text-end mb-3">
                <button className="btn btn-warning mr-6">
                <Link
                    to={`/apps-publicities`}
                    className="text-white"
                >
                    {props.t("Retour")}
                </Link>
                </button>
               
            </div>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

PublicitiesDetail.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(PublicitiesDetail));