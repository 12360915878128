import React, { useEffect, useState} from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { EditorState} from 'draft-js';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import {
  Card,
  Col,
  Container,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";

// Redux
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const AssistancesDetail= (props) => {
 
  document.title = "Assistance détail";

  const articlesId=useParams('id');
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const url = "https://prod.leconseillerdubtp.com/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;


    const [assistance, setAssistance] = useState({})
    const [response, setReponse] = useState([])
    useEffect(() => {
      const checkTokenValidity = async () => {
          try {
          const response = await axios.get(`${url}/assistances/${articlesId.id}/`, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          setAssistance(response)
          setReponse(assistance.response)
          } catch (error) {
          console.log(error)
          } 
      };
      checkTokenValidity();
    }, [assistance]);


  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.t("Assistance reponse")} pageTitle={props.t("Assistances")} />
        <Row  style={{ display: 'flex', flexDirection: 'column' }}>
          <Col lg={12}>
          <div>
            <Card className="p-4 h-screen">
              <div className="rounded p-4 " style={{border:'orange', borderStyle:'solid'}}> <Label>{props.t("QUESTION")}</Label> <div> {assistance && assistance.content_text}</div> </div>
              <div className="p-2">{assistance && assistance.request_date}</div>
              {response && response.map((item, key) => (
                <div key={key}>
                  <div className="rounded p-4 mt-4" style={{ border: 'green', borderStyle: 'solid', textAlign:'right' }} >
                    <Label>{props.t("REPONSE")}</Label>
                    <div>{item.response_text}</div>
                  </div>
                  <div className="p-2" style={{textAlign:'right'}}>{item.response_date}</div>
                </div>
                ))}

            </Card>
            <Row>
              <Col lg={6}>
                <Card className="h-screen rounded p-2">
                  <Label style={{fontWeight:'bold'}}>{props.t("INFORMATIONS DU CLIENT")}</Label>
                </Card>
                <Card className="p-2 h-screen">
                  {assistance && assistance.user &&
                      <div>
                        <div >{assistance.user.profile && assistance.user.profile.first_name +" "+ assistance.user.profile.last_name }</div>
                        <div>{assistance.user.profile && assistance.user.profile.user_type}</div>
                        <div>{assistance.user.profile && assistance.user.profile.city +" , "+ assistance.user.profile.country }</div>
                        <div>{assistance.user.email}</div>
                      </div>
                  }
                </Card>
              </Col>
              <Col lg={6}>
                <Card className="h-screen rounded p-2">
                  <Label style={{fontWeight:'bold'}}>{props.t("INTERVENANTS")}</Label>
                </Card>
                
                  {response && response.map((item, key) => (
                    <div key={key}>
                    <Card className=" p-2 h-screen">
                      <div >
                        <div>{item.responded_by && item.responded_by.profile && item.responded_by.profile.first_name + " " + item.responded_by.profile.last_name}</div>
                        <div>{item.responded_by && item.responded_by.email }</div>
                        <div>{item.responded_by && item.responded_by.profile && item.responded_by.profile.user_type}</div>
                      </div>
                      </Card>
                    </div>
                    ))}
                
              </Col>
            </Row>
          </div>   
          </Col>
        </Row>
      </Container>
    </div>
  );
};
AssistancesDetail.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(AssistancesDetail));

