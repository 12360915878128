import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Container, Row } from "reactstrap";

// RangeSlider
import "nouislider/distribute/nouislider.css";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import TableContainer from "../../Components/Common/TableContainer";

//redux
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { red } from "@material-ui/core/colors";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const Questions = (props) => {
  const token = JSON.parse(sessionStorage.getItem("authUser"))
    ? JSON.parse(sessionStorage.getItem("authUser")).access
    : null;
  const dispatch = useDispatch();

  // Definition des etats
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []);

  const [assistances, setAssistances] = useState([]);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get(
          "https://prod.leconseillerdubtp.com/api/v1/questions/",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setAssistances(response);
      } catch (error) {
        console.log(error);
      }
    };
    checkTokenValidity();
  }, []);

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get(
          "https://prod.leconseillerdubtp.com/api/v1ers/list/",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setUserList(response);
      } catch (error) {
        console.log(error);
      }
    };
    checkTokenValidity();
  }, []);

  const users = useCallback(
    (j) => {
      for (let i = 0; i <= userList.length; i++) {
        if (userList[i] && j === userList[i]?.id) {
          const name = userList[i]?.username;
          return name;
        }
      }
    },
    [userList]
  );

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const handleDeleteProduct = () => {
    if (assistances) {
      // dispatch(deleteProducts(articles.id));
      setDeleteModal(false);
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      // dispatch(deleteProducts(element.value));
      del.style.display = "none";
    });
  };

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".entrepriseCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="entrepriseCheckBox"
              value={cellProps.row.original.id}
            />
          );
        },
        id: "#",
      },
      {
        Header: props.t("ENVOYE PAR"),
        Cell: (assistance) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1" style={{ fontWeight: "bold" }}>
                {assistance.row.original.requestor
                  ? assistance.row.original.requestor.profile &&
                    assistance.row.original.requestor.profile?.first_name +
                      " " +
                      assistance.row.original.requestor.profile?.last_name
                  : null}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("REPONSES"),
        accessor: "_response",
        Cell: (assistance) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <p>{assistance.row.original?._response.length}</p>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("QUESTION"),
        Cell: (assistance) => (
          <>
            <div
              className="d-flex align-items-center"
              style={{
                width: 300,
                height: 70,
                overflow: "clip",
                verticalAlign: "middle",
              }}
            >
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <p>{assistance.row.original.question_text}</p>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("PRIS PAR"),
        Cell: (assistance) => (
          <>
            <div>
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <p style={{ fontStyle: "italic" }}>
                    {assistance.row.original.taken_by ? (
                      users(assistance.row.original.taken_by)
                    ) : (
                      <h5
                        className="mb-1 badge badge-soft-danger me-1 "
                        style={{ textAlign: "center" }}
                      >
                        Non
                      </h5>
                    )}
                  </p>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("DEJA REPONDU"),
        Cell: (assistance) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <p>
                    {assistance.row.original.responded ? (
                      <h5
                        className="mb-1 badge badge-soft-success me-1 "
                        style={{ textAlign: "center" }}
                      >
                        Répondu
                      </h5>
                    ) : (
                      <h5
                        className="mb-1 badge badge-soft-warning me-1 "
                        style={{ textAlign: "center" }}
                      >
                        En attente
                      </h5>
                    )}
                  </p>
                </h5>
              </div>
            </div>
          </>
        ),
      },

      {
        Header: "ACTIONS",
        Cell: (cellProps) => {
          const assistancesData = cellProps.row.original;
          return (
            <>
              {!assistancesData.taken && (
                <li className="list-inline-item" title={props.t("Répondre")}>
                  {
                    <div className="btn btn-soft-warning">
                      <Link to={`apps-questions-add/${assistancesData.id}/`}>
                        Répondre
                      </Link>
                    </div>
                  }
                </li>
              )}
              <li className="list-inline-item" title={props.t("Voir")}>
                <Link
                  to={`apps-questions-details/${assistancesData.id}/`}
                  className="text-warning d-inline-block"
                >
                  <i className="ri-eye-fill align-bottom"></i>
                </Link>
              </li>
            </>
          );
        },
      },
    ],
    [props, users]
  );

  document.title = props.t("Questions/Réponses");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb
          title={props.t("Questions/Réponses")}
          pageTitle={props.t("Questions/Réponses")}
        />
        <Row>
          <div className="col-xl-12 col-lg-12">
            <div>
              <div className="card">
                <div className="card-body pt-0">
                  {isLoading ? ( // If loading, show loading indicator
                    <div className="py-4 text-center">
                      <div>
                        <lord-icon
                          src="https://cdn.lordicon.com/xjovhxra.json"
                          trigger="loop"
                          colors="primary:#0960b6,secondary:#02376b"
                          style={{ width: "72px", height: "72px" }}
                        ></lord-icon>
                      </div>
                      <div className="mt-4">
                        <h5>{props.t("Chargement")}...</h5>
                      </div>
                    </div>
                  ) : assistances ? (
                    <div>
                      <TableContainer
                        columns={columns}
                        data={assistances || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        divClass="table-responsive mb-1"
                        tableClass="mb-0 align-middle "
                        theadClass="table-light text-muted"
                      />
                    </div>
                  ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5>{props.t("Désolé, pas de resultats trouvés")}</h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

Questions.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(Questions));
