import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import DashboardAnalytics from "../pages/DashboardAnalytics";
import Dashboard from "../pages/DashboardCrm";

import DashboardCrypto from "../pages/DashboardCrypto";
// import DashboardProject from "../pages/DashboardProject";
import DashboardNFT from "../pages/DashboardNFT";

//Crm Pages
import AbonnementInfo from "../pages/Abonnement/AbonnementInfo";
import SuccessMsgAbo from "../pages/Abonnement/SuccessMsgAbo";
import TypeAbonnementList from "../pages/Abonnement/TypeAbonnementList";
import CrmLeads from "../pages/Crm/CrmLeads/index";
import CrmParticuliers from "../pages/Crm/CrmParticuliers";
import CrmEntreprises from "../pages/Crm/Entreprises/CrmEntreprises";
import CrmDeals from "../pages/Opportunites";

//Ventes Pages
import Ventes from "../pages/Ventes/index";
import VenteAddNewVente from "../pages/Ventes/VenteAddNewVente";
import VenteDeleteVente from "../pages/Ventes/VenteDeleteVente";
import VenteUpdateVente from "../pages/Ventes/VenteUpdateVente";

//Depenses Pages
import DepenseAddNew from "../pages/Depenses/DepenseAddNew";
import DepenseDetails from "../pages/Depenses/DepenseDetails";
import DepenseUpdate from "../pages/Depenses/DepenseUpdate";
import Depenses from "../pages/Depenses/index";

//Metiers Pages
import Metiers from "../pages/Activities/Metiers";
import MetiersAdd from "../pages/Activities/Metiers/MetiersAdd";
import MetiersUpdate from "../pages/Activities/Metiers/MetiersUpdate";

//Achat Pages
import AchatsAddNew from "../pages/Achats/AchatsAddNew";
import AchatsDetails from "../pages/Achats/AchatsDetails";
import AchatsUpdate from "../pages/Achats/AchatsUpdate";
import Achats from "../pages/Achats/index";

//Avoirs Pages
import AvoirsAdd from "../pages/Documents/Avoirs/AvoirsAdd";
import AvoirsView from "../pages/Documents/Avoirs/AvoirsView";
import Avoirs from "../pages/Documents/Avoirs/index";

//Devis Pages
import DevisAdd from "../pages/Documents/Devis/DevisAdd";
import DevisView from "../pages/Documents/Devis/DevisView";
import Devis from "../pages/Documents/Devis/index";

//Factures Pages
import Factures from "../pages/Documents/Factures";
import FactureAdd from "../pages/Documents/Factures/FactureAdd";
import FactureView from "../pages/Documents/Factures/FactureView";

//Factures Proformas Pages
import FactureProformas from "../pages/Documents/FactureProforma";
import FactureProformaAdd from "../pages/Documents/FactureProforma/FactureProformaAdd";
import FactureProformaView from "../pages/Documents/FactureProforma/FactureProformaView";

//Bon de Commande Pages
import BonCommande from "../pages/Documents/BonCommande";
import BonCommandeAdd from "../pages/Documents/BonCommande/BonCommandeAdd";
import BonCommandeView from "../pages/Documents/BonCommande/BonCommandeView";

//Bon de Livraison Pages
import BonLivraison from "../pages/Documents/BonLivraison";
import BonLivraisonAdd from "../pages/Documents/BonLivraison/BonLivraisonAdd";
import BonLivraisonView from "../pages/Documents/BonLivraison/BonLivraisonView";

//Abonnement Pages
import Pricing from "../pages/Abonnement/Pricing";
import Enterprise from "../pages/Pages/Team/Enterprise";
import UserDetail from "../pages/Pages/Team/profilTeam";
import Team from "../pages/Pages/Team/Team";

// Opportunités
import OpportunitesAddNew from "../pages/Opportunites/OpportunitesAddNew";
import OpportunitesDetails from "../pages/Opportunites/OpportunitesDetails";
import OpportunitesUpdate from "../pages/Opportunites/OpportunitesUpdate";

//AuthenticationInner pages
import AffiliateDashboard from "../pages/AuthenticationInner/Affiliate/AffiliateDashboard";
import AffiliateSignIn from "../pages/AuthenticationInner/Affiliate/AffiliateSignIn";
import AffiliateSignInEn from "../pages/AuthenticationInner/Affiliate/AffiliateSignInEn";
import AffiliateSignUp from "../pages/AuthenticationInner/Affiliate/AffiliateSignUp";
import AffiliateSignUpEn from "../pages/AuthenticationInner/Affiliate/AffiliateSignUpEn";
import BasicSignIn from "../pages/AuthenticationInner/Login/BasicSignIn";
import CoverSignIn from "../pages/AuthenticationInner/Login/CoverSignIn";
import BasicPasswReset from "../pages/AuthenticationInner/PasswordReset/BasicPasswReset";
import BasicSignUp from "../pages/AuthenticationInner/Register/BasicSignUp";
import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";
import CoverSignUpEn from "../pages/AuthenticationInner/Register/CoverSignUpEn";

//Ecommerce Pages
import ArticlesAdd from "../pages/Activities/Articles/ArticlesAdd";
import ArticlesDetails from "../pages/Activities/Articles/ArticlesDetails";
import ArticlesUpdate from "../pages/Activities/Articles/ArticlesUpdate";
import Articles from "../pages/Activities/Articles/index";
import EcommerceAddCategories from "../pages/Activities/Categories/EcommerceAddCategories";
import EcommerceUpdateCategories from "../pages/Activities/Categories/EcommerceUpdateCategories";
import Categories from "../pages/Activities/Categories/index";

//Task
import KanbanBoard from "../pages/Tasks/KanbanBoard/Index";
import TaskDetails from "../pages/Tasks/TaskDetails";
import TaskList from "../pages/Tasks/TaskList";

//pages
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Cover404 from "../pages/AuthenticationInner/Errors/Cover404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";
import BasicLockScreen from "../pages/AuthenticationInner/LockScreen/BasicLockScr";
import CoverLockScreen from "../pages/AuthenticationInner/LockScreen/CoverLockScr";
import BasicLogout from "../pages/AuthenticationInner/Logout/BasicLogout";
import CoverLogout from "../pages/AuthenticationInner/Logout/CoverLogout";
import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import CoverPasswReset from "../pages/AuthenticationInner/PasswordReset/CoverPasswReset";
import BasicSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg";
import CoverSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg";
import SuccessMsg from "../pages/AuthenticationInner/SuccessMessage/SuccessMsg";
import SuccessMsgEn from "../pages/AuthenticationInner/SuccessMessage/SuccessMsgEn";
import BasicTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify";
import CoverTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify";
import ContactsDetail from "../pages/Crm/ContactsDetail";
import CompanieSettings from "../pages/Pages/Profile/Settings/CompanieSettings";
import Settings from "../pages/Pages/Profile/Settings/Settings";
import SimplePage from "../pages/Pages/Profile/SimplePage/SimplePage";
import Stock from "../pages/stock/Stock";

//login
import ArticleValidation from "../pages/Activities/Articles/ArticleValidation";
import Activate from "../pages/Authentication/Activate";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import LoginAdmin from "../pages/Authentication/LoginAdmin";
import LoginEn from "../pages/Authentication/LoginEn";
import Logout from "../pages/Authentication/Logout";

//Administration
import Abonnes from "../pages/Administration/Abonnes";

// User Profile
import AbonnesFormule from "../pages/Administration/AbonnesFormule";
import AdminsDemos from "../pages/Administration/AdminsDemos";
import DemosAdmins from "../pages/Administration/DemosAdmins";
import SimplePageAdmin from "../pages/Administration/SimplePageAdmin";
import UserProfile from "../pages/Authentication/user-profile";
import BasicPasswResetEn from "../pages/AuthenticationInner/PasswordReset/BasicPasswResetEn";
import BasicSuccessMsgEn from "../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsgEn";
import ContactsDetailEn from "../pages/Crm/Entreprises/ContactsDetailEn";

import Collaborateurs from "../pages/Pages/Collaborateurs";
import CollaborateursAdd from "../pages/Pages/Collaborateurs/CollaborateursAdd";
import CollaborateursDetails from "../pages/Pages/Collaborateurs/CollaborateursDetails";

import Recrutements from "../pages/Recrutements";
import RecrutementsAdd from "../pages/Recrutements/RecrutementsAdd";
import RecrutementsDetails from "../pages/Recrutements/RecrutementsDetails";
import RecrutementsUpdate from "../pages/Recrutements/RecrutementsUpdate";

import AppelsdOffres from "../pages/AppelsdOffres";
import AppelsdOffresAdd from "../pages/AppelsdOffres/AppelsdOffresAdd";
import AppelsdOffresDetails from "../pages/AppelsdOffres/AppelsdOffresDetails";
import AppelsdOffresUpdate from "../pages/AppelsdOffres/AppelsdOffresUpdate";
import Experts from "../pages/Experts";
import ExpertsDetails from "../pages/Experts/ExpertsDetails";
import ExpertValidtion from "../pages/Experts/ExpertValidtion";

import Questions from "../pages/Questions";
import QuestionsAdd from "../pages/Questions/QuestionsAdd";
import QuestionsDetails from "../pages/Questions/QuestionsDetails";
import QuestionsToResponded from "../pages/Questions/QuestionsToResponded";

import Formations from "../pages/Formations";
import FormationsAdd from "../pages/Formations/FormationsAdd";
import FormationsDetails from "../pages/Formations/FormationsDetails";
import FormationsUpdate from "../pages/Formations/FormationsUpdate";

import Innovations from "../pages/Innovations";
import InnovationsAdd from "../pages/Innovations/InnovationsAdd";
import InnovationsDetail from "../pages/Innovations/InnovationsDetails";
import InnovationsUpdate from "../pages/Innovations/InnovationsUpdate";

import AbonnemenType from "../pages/TypeAbonnement";
import AbonnementsAdd from "../pages/TypeAbonnement/AbonnementsAdd";
import AbonnementsDetails from "../pages/TypeAbonnement/AbonnementsDetails";
import AbonnementUpdate from "../pages/TypeAbonnement/AbonnementUpdate";

import Assistance from "../pages/Assistance";
import AssistancesAdd from "../pages/Assistance/AssistancesAdd";
import AssistancesDetail from "../pages/Assistance/AssistancesDetail";
import AssistancesToResponded from "../pages/Assistance/AssistancesToResponded";

import Publicities from "../pages/Publicities";
import PublicitiesAdd from "../pages/Publicities/PublicitiesAdd";
import PublicitiesDetails from "../pages/Publicities/PublicitiesDetails";
import PublicitiesUpdate from "../pages/Publicities/PublicitiesUpdate";
import PublicitiesValidation from "../pages/Publicities/PublicitiesValidation";

import FormationPro from "../pages/FormationPro";
import Professionnel from "../pages/Pages/Team/Professionnel";

import Fonctions from "../pages/Fonctions";
import FormationLevel from "../pages/FormationLevel";
import CollaborateursUpdate from "../pages/Pages/Collaborateurs/CollaborateursUpdate";

import Banks from "../pages/Pages/Team/Banks";
import Certification from "../pages/Pages/Team/Certification";
import Organisme from "../pages/Pages/Team/Organisme";
import Particulier from "../pages/Pages/Team/Particulier";

// Pays
import AddArrondissement from "../pages/Geolocalisation/Arrondissement/AddArrondissement";
import Arrondissement from "../pages/Geolocalisation/Arrondissement/Arrondissement";
import UpdateArrondissement from "../pages/Geolocalisation/Arrondissement/UpdateArrondissement";
import AddCity from "../pages/Geolocalisation/City/AddCity";
import City from "../pages/Geolocalisation/City/City";
import UpdateCity from "../pages/Geolocalisation/City/UpdateCity";
import AddPays from "../pages/Geolocalisation/Pays/AddPays";
import Pays from "../pages/Geolocalisation/Pays/Pays";
import UpdatePays from "../pages/Geolocalisation/Pays/UpdatePays";
import AddProvince from "../pages/Geolocalisation/Province/AddProvince";
import Province from "../pages/Geolocalisation/Province/Province";
import UpdateProvince from "../pages/Geolocalisation/Province/UpdateProvince";
import AddQuartier from "../pages/Geolocalisation/Quartier/AddQuartier";
import Quartier from "../pages/Geolocalisation/Quartier/Quartier";
import UpdateQuartier from "../pages/Geolocalisation/Quartier/UpdateQuartier";
import AddTeamMember from "../pages/Pages/Team/AddTeamMember";
import UsersCategories from "../pages/Structures";
import AddCategories from "../pages/Structures/AddCategories";
import UpdateCategories from "../pages/Structures/UpdateCategories";

const authProtectedRoutes = [
  { path: "/dashboard-analytics", component: DashboardAnalytics },
  { path: "/dashboard-crm", component: Dashboard },
  { path: "/dashboard", component: Dashboard },
  { path: "/index", component: Dashboard },
  { path: "/dashboard-crypto", component: DashboardCrypto },
  // { path: "/dashboard-projects", component: DashboardProject },
  { path: "/dashboard-nft", component: DashboardNFT },

  { path: "/profile-users/:id/", component: UserDetail },
  { path: "/apps-particuliers", component: Particulier },
  { path: "/apps-banks", component: Banks },
  //Gestions des stocks
  { path: "/apps-stock", component: Stock },

  { path: "/apps-formationspro", component: FormationPro },

  //Depenses
  { path: "/apps-depenses", component: Depenses },
  { path: "/apps-depenses-add", component: DepenseAddNew },
  { path: "/apps-depenses-update/:id", component: DepenseUpdate },
  { path: "/apps-depenses-details/:id", component: DepenseDetails },

  { path: "/apps-formations", component: Formations },
  { path: "/apps-formations-add", component: FormationsAdd },
  { path: "/apps-formations-update/:id", component: FormationsUpdate },
  { path: "/apps-formations-details/:id", component: FormationsDetails },

  { path: "/apps-innovations", component: Innovations },
  { path: "/apps-innovations-add", component: InnovationsAdd },
  { path: "/apps-innovations-update/:id", component: InnovationsUpdate },
  { path: "/apps-innovations-details/:id", component: InnovationsDetail },

  { path: "/apps-fonctions", component: Fonctions },

  { path: "/apps-level", component: FormationLevel },

  { path: "/apps-assistances", component: Assistance },
  { path: "/apps-assistances-add/:id", component: AssistancesAdd },
  { path: "/apps-assistances-detail/:id", component: AssistancesDetail },
  { path: "/apps-assistances-toResponded", component: AssistancesToResponded },

  { path: "/apps-opportunites", component: CrmDeals },
  { path: "/apps-opportunites-add", component: OpportunitesAddNew },
  { path: "/apps-opportunites-update/:id", component: OpportunitesUpdate },
  { path: "/apps-opportunites-details/:id", component: OpportunitesDetails },

  //Achats
  { path: "/apps-achats", component: Achats },
  { path: "/apps-achats-add", component: AchatsAddNew },
  { path: "/apps-achats-update/:id", component: AchatsUpdate },
  { path: "/apps-achats-details/:id", component: AchatsDetails },

  { path: "/apps-questions", component: Questions },
  { path: "/apps-questions-add/:id", component: QuestionsAdd },
  { path: "/apps-questions-details/:id", component: QuestionsDetails },
  { path: "/apps-questions-toResponded/:id", component: QuestionsToResponded },

  { path: "/apps-type", component: AbonnemenType },
  { path: "/apps-type-add/", component: AbonnementsAdd },
  { path: "/apps-type-details/:id", component: AbonnementsDetails },
  { path: "/apps-type-update/:id", component: AbonnementUpdate },

  //Ventes
  { path: "/apps-ventes", component: Ventes },
  { path: "/apps-vente-add", component: VenteAddNewVente },
  { path: "/apps-vente-update/:id", component: VenteUpdateVente },
  { path: "/apps-vente-delete", component: VenteDeleteVente },

  //Devis
  { path: "/apps-documents-devis", component: Devis },
  { path: "/apps-documents-devis-add", component: DevisAdd },
  { path: "/apps-documents-devis-view/:id", component: DevisView },
  // { path: "/apps-documents-devis-update/:id", component: DevisUpdate },

  //Factures
  { path: "/apps-documents-factures", component: Factures },
  { path: "/apps-documents-facture-add", component: FactureAdd },
  // { path: "/apps-documents-facture-add/:numVente", component: FactureAdd },
  { path: "/apps-documents-facture-view/:id", component: FactureView },

  //Factures Proformas
  { path: "/apps-documents-fproforma", component: FactureProformas },
  { path: "/apps-documents-fproforma-add", component: FactureProformaAdd },
  {
    path: "/apps-documents-fproforma-view/:id",
    component: FactureProformaView,
  },

  //Avoirs
  { path: "/apps-documents-avoirs", component: Avoirs },
  { path: "/apps-documents-avoir-add", component: AvoirsAdd },
  { path: "/apps-documents-avoir-add/:id", component: AvoirsAdd },
  { path: "/apps-documents-avoir-view/:id", component: AvoirsView },

  { path: "/apps-documents-bcommande", component: BonCommande },
  { path: "/apps-documents-bcommande-add", component: BonCommandeAdd },
  { path: "/apps-documents-bcommande-view/:id", component: BonCommandeView },

  { path: "/apps-documents-blivraison", component: BonLivraison },
  { path: "/apps-documents-blivraison-add", component: BonLivraisonAdd },
  { path: "/apps-documents-blivraison-add/:id", component: BonLivraisonAdd },
  { path: "/apps-documents-blivraison-view/:id", component: BonLivraisonView },

  //Ecommerce
  { path: "/apps-articles", component: Articles },
  { path: "/apps-articles-validation/:id", component: ArticleValidation },
  { path: "/apps-articles-update/:id", component: ArticlesUpdate },
  { path: "/apps-articles-details/:id", component: ArticlesDetails },
  { path: "/apps-articles-add", component: ArticlesAdd },
  //Ecommerce
  { path: "/apps-publicities", component: Publicities },
  {
    path: "/apps-publicities-validation/:id",
    component: PublicitiesValidation,
  },
  { path: "/apps-publicities-update/:id", component: PublicitiesUpdate },
  { path: "/apps-publicities-details/:id", component: PublicitiesDetails },
  { path: "/apps-publicities-add", component: PublicitiesAdd },

  { path: "/apps-collaborateurs", component: Collaborateurs },
  {
    path: "/apps-collaborateurs-details/:id",
    component: CollaborateursDetails,
  },
  { path: "/apps-collaborateurs-update/:id", component: CollaborateursUpdate },
  { path: "/apps-collaborateurs-add", component: CollaborateursAdd },

  //Experts
  { path: "/apps-experts", component: Experts },
  { path: "/apps-experts-details/:id", component: ExpertsDetails },
  { path: "/apps-experts-validation/:id", component: ExpertValidtion },

  { path: "/apps-metiers", component: Metiers },
  { path: "/apps-metiers-update/:id", component: MetiersUpdate },
  { path: "/apps-metiers-add", component: MetiersAdd },

  { path: "/apps-categories-add", component: EcommerceAddCategories },
  { path: "/apps-categories-update/:id", component: EcommerceUpdateCategories },
  { path: "/apps-categories", component: Categories },

  { path: "/apps-recrutements", component: Recrutements },
  { path: "/apps-recrutements-update/:id", component: RecrutementsUpdate },
  { path: "/apps-recrutements-details/:id", component: RecrutementsDetails },
  { path: "/apps-recrutements-add", component: RecrutementsAdd },

  //Appels d'Offres
  { path: "/apps-appels-offres", component: AppelsdOffres },
  { path: "/apps-appels-offres-add", component: AppelsdOffresAdd },
  { path: "/apps-appels-offres-update/:id", component: AppelsdOffresUpdate },
  { path: "/apps-appels-offres-details/:id", component: AppelsdOffresDetails },

  //Task
  { path: "/apps-tasks-list-view", component: TaskList },
  { path: "/apps-tasks-details/:id", component: TaskDetails },
  { path: "/apps-tasks-kanban", component: KanbanBoard },

  // Type Abonnement
  { path: "/apps-crm-type-abonnement", component: TypeAbonnementList },

  // Type Abonnement
  { path: "/apps-crm-abonnement/:id", component: SuccessMsgAbo },

  // Info Abonnement
  { path: "/apps-crm-abonnements-info", component: AbonnementInfo },

  //Crm
  { path: "/apps-crm-particuliers", component: CrmParticuliers },
  { path: "/apps-crm-particuliers/:id", component: ContactsDetail },
  { path: "/apps-crm-entreprise/:id", component: CompanieSettings },
  { path: "/apps-crm-entreprises", component: CrmEntreprises },
  { path: "/apps-crm-entreprises/:id", component: ContactsDetailEn },
  { path: "/apps-deals", component: CrmDeals },
  { path: "/apps-crm-leads", component: CrmLeads },
  { path: "/pages-profile", component: SimplePage },
  { path: "/pages-profile/:id", component: Settings },
  { path: "/certification", component: Certification },
  //Abonnement
  { path: "/apps-crm-abonnements", component: Pricing },

  //Team
  { path: "/pages-team", component: Team },
  { path: "/pages-team-add-user", component: AddTeamMember },
  { path: "/apps-organismes", component: Organisme },
  { path: "/apps-professionals", component: Professionnel },
  { path: "/apps-enterprises", component: Enterprise },
  { path: "/pages-admin-team", component: AdminsDemos },
  { path: "/pages-demos-team", component: DemosAdmins },

  //User Profile
  { path: "/profile", component: UserProfile },

  //Administration Access
  { path: "/apps-admin-users", component: Abonnes },
  { path: "/apps-admin-abonnes", component: AbonnesFormule },
  { path: "/apps-admin-abonne-detail/:id", component: SimplePageAdmin },

  // Pays
  { path: "/apps-geolocalisation-pays", component: Pays },
  { path: "/apps-geolocalisation-pays-add", component: AddPays },
  { path: "/apps-geolocalisation-pays-update/:id", component: UpdatePays },

  // Province
  { path: "/apps-geolocalisation-province", component: Province },
  { path: "/apps-geolocalisation-province-add", component: AddProvince },
  {
    path: "/apps-geolocalisation-province-update/:id",
    component: UpdateProvince,
  },

  // City
  { path: "/apps-geolocalisation-city", component: City },
  { path: "/apps-geolocalisation-city-add", component: AddCity },
  { path: "/apps-geolocalisation-city-update/:id", component: UpdateCity },

  // Arrondissement
  { path: "/apps-geolocalisation-arrondissement", component: Arrondissement },
  {
    path: "/apps-geolocalisation-arrondissement-add",
    component: AddArrondissement,
  },
  {
    path: "/apps-geolocalisation-arrondissement-update/:id",
    component: UpdateArrondissement,
  },

  // Quartier
  { path: "/apps-geolocalisation-quartier", component: Quartier },
  { path: "/apps-geolocalisation-quartier-add", component: AddQuartier },
  {
    path: "/apps-geolocalisation-quartier-update/:id",
    component: UpdateQuartier,
  },

  // Categorie des utilisateurs
  { path: "/apps-utilisateurs-categories-add", component: AddCategories },
  {
    path: "/apps-utilisateurs-categories-update/:id",
    component: UpdateCategories,
  },
  { path: "/apps-utilisateurs-categories", component: UsersCategories },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];
const affiliateProtectedRoutes = [
  { path: "/affiliate/dashboard", component: AffiliateDashboard },
];
const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/fr/connexion", component: Login },
  { path: "/en/login", component: LoginEn },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/activate/:uid/:token", component: Activate },
  { path: "/admin", component: LoginAdmin },

  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: BasicSignIn },
  { path: "/auth-signin-cover", component: CoverSignIn },
  { path: "/auth-signup-basic", component: BasicSignUp },
  { path: "/fr/inscription", component: CoverSignUp },
  { path: "/en/register", component: CoverSignUpEn },
  { path: "/fr/partners", component: AffiliateSignUp },
  { path: "/en/partners", component: AffiliateSignUpEn },
  { path: "/affiliate/fr/connexion", component: AffiliateSignIn },
  { path: "/affiliate/en/login", component: AffiliateSignInEn },
  { path: "/auth-pass-reset-basic", component: BasicPasswReset },
  { path: "/auth-pass-reset-basic-en", component: BasicPasswResetEn },
  { path: "/auth-pass-reset-cover", component: CoverPasswReset },
  { path: "/auth-lockscreen-basic", component: BasicLockScreen },
  { path: "/auth-lockscreen-cover", component: CoverLockScreen },
  { path: "/auth-logout-basic", component: BasicLogout },
  { path: "/auth-logout-cover", component: CoverLogout },
  { path: "/auth-success-msg-basic", component: BasicSuccessMsg },
  { path: "/auth-success-msg-basic-en", component: BasicSuccessMsgEn },
  { path: "/auth-success-msg", component: SuccessMsg },
  { path: "/auth-success-msg-en", component: SuccessMsgEn },
  { path: "/auth-success-msg-cover", component: CoverSuccessMsg },
  { path: "/auth-twostep-basic", component: BasicTwosVerify },
  { path: "/auth-twostep-cover", component: CoverTwosVerify },
  { path: "/auth-404-basic", component: Basic404 },
  { path: "/auth-404-cover", component: Cover404 },
  { path: "/auth-404-alt", component: Alt404 },
  { path: "/auth-500", component: Error500 },
  { path: "/reset_password/:uid/:token", component: BasicPasswCreate },
  { path: "/auth-pass-change-cover", component: CoverPasswCreate },
  { path: "/auth-offline", component: Offlinepage },
];

export { affiliateProtectedRoutes, authProtectedRoutes, publicRoutes };
