import axios from "axios";
import classnames from "classnames";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useParams, withRouter } from "react-router-dom";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import * as Yup from "yup";
import BreadCrumb from "../../Components/Common/BreadCrumb";
SwiperCore.use([FreeMode, Navigation, Thumbs]);

function ExpertsDetails(props) {
  const articlesId = useParams("id");
  const history = useHistory();
  const token = JSON.parse(sessionStorage.getItem("authUser"))
    ? JSON.parse(sessionStorage.getItem("authUser")).access
    : null;

  const [selectAticle, setSelectArticle] = useState({});
  const url = "https://prod.leconseillerdubtp.com/api/v1";
  const [competences, setCompetences] = useState([]);
  const [references, setReferences] = useState([]);

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get(
          "https://prod.leconseillerdubtp.com/api/v1ers/list/",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setUserList(response);
      } catch (error) {
        if (error) {
          console.log(error);
        }
      }
    };
    checkTokenValidity();
  }, [token]);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get(`${url}/expert/${articlesId.id}/`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        setSelectArticle(response);
        setCompetences(selectAticle.competences);
        setReferences(selectAticle.references);
      } catch (error) {
        if (error) {
          console.log(error);
        }
      }
    };
    checkTokenValidity();
  }, [url, selectAticle, token]);

  const users = (j) => {
    for (let i = 0; i <= userList.length; i++) {
      if (userList[i] && j === userList[i]?.id) {
        const name = userList[i]?.profile;
        return name.last_name + " " + name.first_name;
      }
    }
  };

  const username = (j) => {
    for (let i = 0; i <= userList.length; i++) {
      if (userList[i] && j === userList[i]?.id) {
        const name = userList[i]?.profile;
        return name.username;
      }
    }
  };

  const email = (j) => {
    for (let i = 0; i <= userList.length; i++) {
      if (userList[i] && j === userList[i]?.id) {
        return userList[i]?.email;
      }
    }
  };
  const photo = (j) => {
    for (let i = 0; i <= userList.length; i++) {
      if (userList[i] && j === userList[i]?.id) {
        const photoProfile = userList[i]?.profile;
        return photoProfile.profile_pic;
      }
    }
  };

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      is_validated: false,
      validation_report: "",
    },

    validationSchema: Yup.object({
      validation_report: Yup.string().required("Ce champ est requis"),
    }),

    onSubmit: (values) => {
      const validatedObject = {
        id: selectAticle.id,
        is_validated: values.is_validated,
        validation_report: values.validation_report,
        message: null,
        recommandations: null,
      };
      if (values.is_validated === false) {
        axios
          .patch(`${url}/expert/${articlesId.id}/unvalidate/`, validatedObject)
          .then((res) => {
            if (res) {
              toast.error(props.t("Expert non validé"), { autoClose: 3000 });
            }
          });
        validation.resetForm();
        history.push("/apps-experts");
      } else {
        axios
          .patch(`${url}/expert/${articlesId.id}/validate/`, validatedObject)
          .then((res) => {
            if (res) {
              toast.success("Expert en validation", { autoClose: 3000 });
            }
          });
        validation.resetForm();
        history.push("/apps-experts");
        setTimeout(() => window.location.reload(true), 2000);
      }
    },
  });
  const validation2 = useFormik({
    enableReinitialize: true,
    initialValues: {
      is_approved: false,
      is_expert: false,
      message: "",
      recommandations: "",
    },

    validationSchema: Yup.object({
      message: Yup.string().required("Ce champ est requis"),
    }),

    onSubmit: (values) => {
      const validatedObject = {
        id: selectAticle.id,
        is_approved: values.is_approved,
        is_expert: true,
        is_validated: true,
        validation_report: selectAticle.validation_report,
        message: values.message,
        recommandations: null,
      };
      if (values.is_approved == false) {
        axios
          .patch(`${url}/expert/${articlesId.id}/reject/`, validatedObject)
          .then(
            (res) => {
              if (res) {
                toast.error(props.t("Expert non approuvé"), {
                  autoClose: 3000,
                });
              }
            },
            (error) => {
              if (error) {
                console.log(error);
              }
            }
          );
        validation.resetForm();
        history.push("/apps-experts");
        setTimeout(() => window.location.reload(true), 2000);
      } else {
        axios
          .patch(`${url}/expert/${articlesId.id}/approve/`, validatedObject)
          .then(
            (res) => {
              if (res) {
                toast.success(props.t("Expert approuvé avec succès"), {
                  autoClose: 3000,
                });
              }
            },
            (error) => {
              if (error) {
                toast.error(props.t("Expert non approuvé"), {
                  autoClose: 3000,
                });
              }
            }
          );
        validation.resetForm();
        history.push("/apps-experts");
        setTimeout(() => window.location.reload(true), 2000);
      }
    },
  });
  const validation3 = useFormik({
    enableReinitialize: true,
    initialValues: {
      is_approved: false,
      is_expert: false,
      is_validated: false,
      message: "",
      recommandations: "",
    },

    validationSchema: Yup.object({
      recommandations: Yup.string().required("Ce champ est requis"),
    }),

    onSubmit: (values) => {
      const validatedObject = {
        recommandations: values.recommandations,
      };
      axios
        .patch(`${url}/expert/${articlesId.id}/recommand/`, validatedObject)
        .then(
          (res) => {
            if (res) {
              toast.success(props.t("Recommandation envoyée"), {
                autoClose: 3000,
              });
            }
          },
          (error) => {
            if (error) {
              toast.error(props.t("Recommandation envoyée non envoyée"), {
                autoClose: 3000,
              });
            }
          }
        );
      validation.resetForm();
      history.push("/apps-experts");
      setTimeout(() => window.location.reload(true), 2000);
    },
  });

  const validation4 = useFormik({
    enableReinitialize: true,
    initialValues: {
      banca_certificate: null,
    },

    onSubmit: (values) => {
      const validatedObject = {
        banca_certificate: values.banca_certificate,
      };
      axios
        .patch(
          `${url}/professional/${selectAticle?.id}/certify/`,
          validatedObject
        )
        .then(
          (res) => {
            if (res) {
              toast.success(props.t("Expert certifié"), { autoClose: 3000 });
            }
          },
          (error) => {
            if (error) {
              toast.error(props.t("Expert non certifié"), { autoClose: 3000 });
            }
          }
        );
      validation.resetForm();
      history.push("/apps-experts");
      setTimeout(() => window.location.reload(true), 2000);
    },
  });

  const tokenInfo = JSON.parse(sessionStorage.getItem("authUser")).role;

  console.log(selectAticle);

  document.title = props.t("Détail d'un expert");
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t("Détail d'un expert")}
          pageTitle={props.t("Experts")}
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <Row className="gx-lg-5">
                      <Col xl={12}>
                        <div className="mt-xl-0 mt-5">
                          <div>
                            <div className="d-flex">
                              <img
                                src={photo(selectAticle.user)}
                                width="100"
                                style={{
                                  borderRadius: "50%",
                                  height: "100px",
                                  width: "100px",
                                  objectFit: "cover",
                                  objectPosition: "center center",
                                  paddingBottom: "10px",
                                }}
                                alt=""
                              />
                              <div
                                style={{
                                  marginLeft: "20px",
                                  marginTop: "8px",
                                }}
                              >
                                <h2
                                  style={{
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {users(selectAticle.user)}{" "}
                                </h2>

                                <span className="text-xl fw-medium">
                                  <p>{selectAticle.profession}</p>
                                  <p className="text-muted">
                                    {selectAticle.last_certificate}
                                  </p>
                                </span>
                              </div>

                              {selectAticle.banca_certificate &&
                                selectAticle.is_validated &&
                                selectAticle.is_approved && (
                                  <div className="flex aligns-items-center justify-items">
                                    <span className="badge badge-soft-primary me-1">
                                      {props.t("Certifié")}{" "}
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>

                          <span>
                            {competences &&
                              competences.map((item, key) => {
                                return (
                                  <>
                                    <span
                                      className="text-xl fw-medium badge badge-soft-warning"
                                      key={key}
                                    >
                                      {item.label}
                                    </span>
                                  </>
                                );
                              })}
                          </span>

                          <div className="product-content mt-3">
                            <Nav tabs className="nav-tabs-custom nav-success">
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: customActiveTab === "1",
                                  })}
                                  onClick={() => {
                                    toggleCustom("1");
                                  }}
                                >
                                  {props.t("Détails")}
                                </NavLink>
                              </NavItem>
                            </Nav>

                            <TabContent
                              activeTab={customActiveTab}
                              className=""
                              id="nav-tabContent"
                            >
                              <TabPane id="nav-speci" tabId="1">
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <tbody>
                                      <tr>
                                        <th scope="row">
                                          {" "}
                                          {props.t("Curriculum Vitae")}
                                        </th>
                                        <td>
                                          {selectAticle.cv ||
                                            props.t("(Non Renseigné)")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">
                                          {" "}
                                          {props.t("Références")}{" "}
                                        </th>
                                        <div>
                                          {references && references.length > 0
                                            ? references.map((item, key) => {
                                                return (
                                                  <td
                                                    className="text-xl fw-medium  "
                                                    key={key}
                                                  >
                                                    <div
                                                      style={{
                                                        width: "250px",
                                                        height: "100px",
                                                      }}
                                                    >
                                                      <div>{item.name} </div>
                                                      <div> {item.email}</div>
                                                      <div>
                                                        {item.phone_number}
                                                      </div>
                                                    </div>
                                                  </td>
                                                );
                                              })
                                            : props.t("(Non Renseigné)")}
                                        </div>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </TabPane>
                            </TabContent>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br></br>
                    <br></br>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {tokenInfo.some((role) => role === "admin") &&
          !selectAticle.banca_certificate && (
            <Form
              encType="multipart/form-data"
              className=" bg-white rounded p-4 my-3"
              onSubmit={(e) => {
                e.preventDefault();
                validation4.handleSubmit();
              }}
            >
              <br></br>
              <Label htmlFor="" className="form-label">
                {props.t("CERTIFICATION DE BANCA")}
              </Label>
              <input
                className="form-control"
                name="banca_certificate"
                type="number"
                min={1}
                max={5}
                value={validation4.values.banca_certificate}
                onBlur={validation4.handleBlur}
                onChange={validation4.handleChange}
                required
              />

              <div className="text-end my-3">
                <button type="submit" className="btn btn-warning mx-3">
                  {props.t("Valider")}
                </button>
              </div>
            </Form>
          )}
        {!selectAticle.recommandations && (
          <Form
            encType="multipart/form-data"
            className=" bg-white rounded p-4"
            onSubmit={(e) => {
              e.preventDefault();
              validation3.handleSubmit();
            }}
          >
            <Label htmlFor="" className="form-label">
              {props.t("RECOMMANDATIONS")}
            </Label>
            <textarea
              className="form-control"
              name="recommandations"
              value={validation3.values.recommandations}
              onBlur={validation3.handleBlur}
              onChange={validation3.handleChange}
              rows="5"
              cols="20"
              required
            />

            <div className="text-end mb-3">
              {tokenInfo.some((role) => role === "admin") ? null : (
                <button className="btn btn-primary ">
                  <Link to={`/apps-experts`} className="text-white">
                    {props.t("Retour")}
                  </Link>
                </button>
              )}

              <button type="submit" className="btn btn-warning m-3">
                {props.t("Envoyer une recommandation")}
              </button>
            </div>
          </Form>
        )}
        {!selectAticle.validation_report && (
          <Form
            encType="multipart/form-data"
            className=" bg-white rounded p-4"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}
          >
            <Label htmlFor="" className="form-label">
              {props.t("RAPPORT DE VALIDATION")}
            </Label>
            <textarea
              className="form-control"
              name="validation_report"
              value={validation.values.validation_report}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              rows="5"
              cols="20"
              required
            />

            <br></br>
            <br></br>
            <input
              type="checkbox"
              name="is_validated"
              className="form"
              style={{
                fontWeight: "30px",
                width: "20px",
                height: "20px",
                marginRight: "5px",
              }}
              value={validation.values.is_validated}
              onChange={validation.handleChange}
            />

            <b> Je valide cet expert </b>

            <div className="text-end mb-3">
              {tokenInfo.some((role) => role === "admin") ? null : (
                <button className="btn btn-primary ">
                  <Link to={`/apps-experts`} className="text-white">
                    {props.t("Retour")}
                  </Link>
                </button>
              )}

              <button type="submit" className="btn btn-warning mx-3">
                {props.t("Valider")}
              </button>
            </div>
          </Form>
        )}
        {tokenInfo.some((role) => role === "admin") &&
          selectAticle.is_approved === false &&
          selectAticle.is_validated === true &&
          !selectAticle.message && (
            <Form
              encType="multipart/form-data"
              className=" bg-white rounded p-4 my-3"
              onSubmit={(e) => {
                e.preventDefault();
                validation2.handleSubmit();
              }}
            >
              <br></br>
              <Label htmlFor="" className="form-label">
                {props.t("MOTIF")}
              </Label>
              <textarea
                className="form-control"
                name="message"
                value={validation2.values.message}
                onBlur={validation2.handleBlur}
                onChange={validation2.handleChange}
                rows="5"
                cols="20"
                required={true}
              />

              <br></br>

              <input
                type="checkbox"
                name="is_approved"
                className="form"
                style={{
                  fontWeight: "30px",
                  width: "20px",
                  height: "20px",
                  marginRight: "5px",
                }}
                value={validation2.values.is_approved}
                onChange={validation2.handleChange}
              />

              <b> J'approuve cet expert </b>

              <br></br>
              <br></br>

              <div className="text-end mb-3">
                <button className="btn btn-primary ">
                  <Link to={`/apps-experts`} className="text-white">
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning mx-3">
                  {props.t("Valider")}
                </button>
              </div>
            </Form>
          )}
        <br></br>
        <Row>
          {selectAticle.validation_report && (
            <Col lg={6}>
              <Card className="p-2 h-screen">
                <Card className="h-screen rounded p-2">
                  <Label style={{ fontWeight: "bold" }}>
                    {props.t("VALIDATION EFFECTUEE PAR ")}
                  </Label>
                </Card>
                {selectAticle && selectAticle.validated_by && (
                  <div className="pb-2">
                    <div>{users(selectAticle.validated_by)}</div>
                    <div>{username(selectAticle.validated_by)}</div>
                    <div>{email(selectAticle.validated_by)}</div>
                    <br></br>
                    <div>{selectAticle.validation_report}</div>
                  </div>
                )}
              </Card>
            </Col>
          )}
          {selectAticle.is_approved && (
            <Col lg={6}>
              <Card className="p-2 h-screen">
                <Card className="h-screen rounded p-2">
                  <Label style={{ fontWeight: "bold" }}>
                    {props.t("APPROBATION EFFECTUE PAR")}
                  </Label>
                </Card>
                {selectAticle && selectAticle.validated_by && (
                  <div className="pb-2">
                    <div>{users(selectAticle.validated_by)}</div>
                    <div>{username(selectAticle.validated_by)}</div>
                    <div>{email(selectAticle.validated_by)}</div>
                    <br></br>
                    <div>{selectAticle.message}</div>
                  </div>
                )}
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}

ExpertsDetails.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(ExpertsDetails));
