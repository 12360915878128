import React, { useEffect, useState} from "react";
import {
  Card,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Form,
} from "reactstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import classnames from "classnames";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import axios from 'axios';
import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import {useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
  
SwiperCore.use([FreeMode, Navigation, Thumbs]);

function RecrutementsDetail(props) {

  const articlesId = useParams('id');
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const history = useHistory()
  const [selectAticle, setSelectArticle] = useState({})
  const [title, setTitle] = useState("")
  const url = "https://prod.leconseillerdubtp.com/api/v1";

  const validation = useFormik({
    enableReinitialize: true,
    initialValues:{},
    onSubmit: () => {
          axios.patch(`${url}/recruitments/${articlesId.id}/publish/`)
          .then(res =>{
            if(res){
              toast.success(props.t("Offre d'emploi bien validée"),{autoClose:3000})
            }
          }, (error) =>{
            if(error){
              toast.error(props.t("Offre d'emploi non validée"),{autoClose:3000})
            }}
          )
          validation.resetForm();
          history.push("/apps-recrutements");
          setTimeout(()=>window.location.reload(true),3000);
    }
  })

  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get(`${url}/recruitments/${articlesId.id}/`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setSelectArticle(response)
        setTitle(selectAticle.title)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  

document.title =props.t("Détail d'une offre d'emploi'");
  return (
    <div className="page-content">
      <Container fluid>        
        <BreadCrumb title={props.t("Détail d'une offre d'emploi")} pageTitle={props.t("Offre d'emploi")} />
        <Row>
          <Col lg={12}>
            <Card className="px-2 pb-4">
            <Row className="gx-lg-5">
                  
                  <Col xl={12}>
                    <div className="mt-xl-0 mt-5" >
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <img
                              src={selectAticle.offer_pic}
                              className="rounded"
                              style={{ height: 'auto', width: 'auto', maxHeight: '80%', maxWidth: '80%', marginTop:'20px'}}
                          />
                      </div>
                      
                      <div className="d-flex" >
                        <div className="flex-wrap gap-3">
                          <br></br>
                            <h2> {"#"}{selectAticle.reference} : {selectAticle.title}
                            <span className="text-xl fw-medium me-1"> 
                                &nbsp;&nbsp;
                                { new Date() >  new Date(selectAticle.closure_date) ? 
                                      ( <h5 className="fs-14 mb-1 badge badge-soft-danger text-xs" style={{textAlign:"center"}}>
                                      {"Cloturé"}
                                    </h5>):(
                                      <h5 className="fs-14 mb-1 badge badge-soft-success text-xs me-1 " style={{textAlign:"center"}}>
                                        {"En cours"}
                                      </h5>
                                      ) }
                            </span>
                            
                            <span className="text-xl fw-medium me-1"> 
                                &nbsp;&nbsp;
                                { selectAticle && selectAticle.offer_type==="job" ? 
                                      ( <h5 className="fs-14 mb-1 badge badge-soft-warning text-xs" style={{textAlign:"center"}}>
                                      {"Offre d'emploi"}
                                    </h5>) :"" }
                            </span>
                            </h2>
                          </div>
                      </div>
                      <div className="m-4 text-muted" style={{textAlign:'justify'}}>
                        <p>
                          {selectAticle.description}
                        </p>
                      </div>

                      <div className="product-content mt-5">
                        <Nav tabs className="nav-tabs-custom nav-success">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "1",
                              })}
                              onClick={() => {
                                toggleCustom("1");
                              }}
                            >
                             { props.t("Détails")}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "2",
                              })}
                              onClick={() => {
                                toggleCustom("2");
                              }}
                            >
                              {props.t("Autres informations")}
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent
                        activeTab={customActiveTab}
                          className="border border-top-0 p-4"
                          id="nav-tabContent"
                        >
                          <TabPane
                            id="nav-speci"
                            tabId="1"
                          >
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row"> {props.t("Date de publication")}</th>
                                    <td className="text-blue-500" style={{color:"blue"}}>{selectAticle.publication_date}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> {props.t("Date de cloture")} </th>
                                    <td className="text-red-500" style={{color:"red"}}>{selectAticle.closure_date}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row"> {props.t("Statut")} </th>
                                    <td className="text-red-500" >{selectAticle?.published_by ? selectAticle?.published_by?.username : "Aucun"}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </TabPane>
                          <TabPane
                            id="nav-detail"
                            tabId="2">
                            <div>
                              <a className="font-size-16 mb-3" href={selectAticle.publication_link} title="Ouvrir le lien">
                                <span>
                                  {selectAticle.publication_link} 
                                  &nbsp;<i className="ri-external-link-fill"></i>
                                </span>
                              </a>
                            </div>
                            <br/>
                            <div style={{ display: 'flex', fontWeight:"bold" }}>  Télécharger le fichier 
                                <a className="text-xs rounded" href={selectAticle.offer_file} title="Télécharger">
                                  &nbsp;<i style={{fontSize: '30px',width:"50%",height:"50%", color:"red" }} className="ri-file-download-fill align-bottom"></i>
                                </a>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                     {
                      !selectAticle.published && 
                      <Form encType="multipart/form-data" className=" bg-white rounded p-4"
                      onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(); 
                      }}
                      >
                
                <div className="text-end mb-3">
                  <button type="submit" className="btn btn-warning mx-3">
                          {props.t("Publier")}
                  </button>
                </div>
                      </Form>
                     }
                      
                    </div>
                  </Col>
                </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
RecrutementsDetail.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(RecrutementsDetail));