import React, { useState, useEffect, useReducer } from 'react';
import { Link, useParams, withRouter, useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
// import {isEmpty} from 'lodash';

//import images
import progileBg from '../../../../assets/images/profile-bg.jpg';
import { axiosIns } from '../../../../utils';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_REQUEST':
            return { ...state, loading: true };
        case 'FETCH_SUCCESS':
            return { ...state, companie: action.payload, loading: false };
        case 'FETCH_FAIL':
            return { ...state, loading: false };

        default:
            return state;
    }
};

const CompanieSettings = (props) => {
    const history = useHistory();

    const [nom, setNom] = useState("")
    const [email, setEmail] = useState("")
    const [telephone, setTelephone] = useState("")
    const [nbrEmploye, setNbrEmploye] = useState("")
    const [formeJuridique, setFormeJuridique] = useState("")
    const [adresse, setAdresse] = useState("")
    const [siteweb, setSiteweb] = useState("")
    const [num_enreg_legal1, setNumEnregLegal1] = useState("")
    const [num_enreg_legal2, setNumEnregLegal2] = useState("")
    const [capitalSocial, setCapitalSocial] = useState("")
    const [logo, setLogo] = useState(null)

    const [
        {
            loading,
            companie,
        },
        dispatch,
    ] = useReducer(reducer, {
        loading: true,
        companie: useParams('id'),
        // companie: {},
        error: '',
    });
    const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch({ type: 'FETCH_DATA' });
                const { data } = await axiosIns.get(`https://prod.leconseillerdubtp.com/api/v1/profile/`, {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                });
                dispatch({ type: 'FETCH_SUCCESS', payload: data });
                setTelephone(data.phone_number)
                console.log(nom);
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, []);

    const [activeTab, setActiveTab] = useState("1");
    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    document.title = props.t("Modifier le profil | Le Conseiller du BTP");
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg profile-setting-img">
                            <img src={progileBg} className="profile-wid-img" alt="" />

                        </div>
                    </div>
                    <Row>
                        <Col xxl={3}>
                            <Card className="mt-n5">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                            <img src={companie.logo}
                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                alt="user-profile" />

                                        </div>
                                        <h5 className="fs-16 mb-1">{companie.nom}</h5>
                                        <p className="text-muted mb-0">{companie.email}</p>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <div className="d-flex align-items-center mb-5">
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">{props.t("Complétez les informations de l'entreprise")}</h5>
                                        </div>

                                    </div>
                                    {companie.nom === null
                                        || companie.email === null
                                        || companie.telephone === null
                                        || companie.adresse === null
                                        || companie.nbrEmploye === null
                                        || companie.siteweb === null
                                        || companie.num_enreg_legal1 === null
                                        || companie.num_enreg_legal2 === null
                                        || companie.capitalSocial === null
                                        || companie.formeJuridique === null
                                        || companie.logo === null
                                        ?
                                        <div className="progress animated-progress custom-progress progress-label">
                                            <div className="progress-bar bg-danger" role="progressbar" style={{ "width": "80%" }}
                                                aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                                                <div className="label">80%</div>
                                            </div>
                                        </div>
                                        :
                                        <div className="progress animated-progress custom-progress progress-label">
                                            <div className="progress-bar bg-success" role="progressbar" style={{ "width": "100%" }}
                                                aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                                <div className="label">100%</div>
                                            </div>
                                        </div>
                                    }

                                </CardBody>
                            </Card>

                        </Col>

                        <Col xxl={9}>
                            <Card className="mt-xxl-n5">
                                <CardHeader>
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "1" })}
                                                onClick={() => {
                                                    tabChange("1");
                                                }}>
                                                <i className="fas fa-home"></i>
                                                {props.t("Détails Entreprise")}
                                            </NavLink>
                                        </NavItem>

                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <Form>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="nameInput" className="form-label">{props.t("Nom de l'entreprise")}</Label>
                                                            <Input type="text" className="form-control" id="nameInput"
                                                                placeholder="Entrer le nom" defaultValue={companie.nom} value={nom} onChange={(e) => setNom(e.target.value)} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="emailInput" className="form-label">{props.t("Email")}</Label>
                                                            <Input type="text" className="form-control" id="emailInput"
                                                                placeholder={props.t("Entrer l'email")} defaultValue={companie.email} value={email} onChange={(e) => setEmail(e.target.value)} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="phonenumberInput" className="form-label">{props.t("Téléphone")}</Label>
                                                            <Input type="text" className="form-control"
                                                                id="phonenumberInput"
                                                                placeholder={props.t("Entrer le numéro de téléphone")}
                                                                defaultValue={companie.telephone}
                                                                value={telephone} onChange={(e) => setTelephone(e.target.value)} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="adresseInput" className="form-label">{props.t("Adresse")}</Label>
                                                            <Input type="text" className="form-control" id="adresseInput"
                                                                placeholder={props.t("Entrer l'adresse")}
                                                                defaultValue={companie.adresse}
                                                                value={adresse} onChange={(e) => setAdresse(e.target.value)} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="nbrEmployeInput" className="form-label">{props.t("Nombre d'employés")}</Label>
                                                            <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="nbrEmployeInput"
                                                                placeholder="Sélectionnez le Nbre d'employé"
                                                                defaultValue={companie.nbrEmploye}
                                                                value={nbrEmploye} onChange={(e) => setNbrEmploye(e.target.value)}>
                                                                <option value="">{props.t("Sélectionner")}</option>
                                                                <option value="10">1-10</option>
                                                                <option value="50">11-50</option>
                                                                <option value="500">51-500</option>
                                                                <option value="1000">{props.t("500 Et Plus")}</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="formeJuridiqueInput" className="form-label">{props.t("Forme Juridique")}</Label>
                                                            <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="formeJuridiqueInput"
                                                                placeholder="Sélectionnez la forme Juridique"
                                                                defaultValue={companie.formeJuridique}
                                                                value={formeJuridique} onChange={(e) => setFormeJuridique(e.target.value)}>
                                                                <option value="">{props.t("Sélectionner")}</option>
                                                                <option value="Entreprise individuelle">{props.t("Entreprise individuelle")}</option>
                                                                <option value="Entreprise publique">{props.t("Entreprise publique")}</option>
                                                                <option value="Société anonyme">{props.t("Société anonyme")}</option>
                                                                <option value="Société à responsabilité limitée">{props.t("Société à responsabilité limitée")}</option>
                                                                <option value="Société privée à responsabilité limitée">{props.t("Société privée à responsabilité limitée")}</option>
                                                                <option value="Société à responsabilité limitée par garantie">{props.t("Société à responsabilité limitée par garantie")}</option>
                                                                <option value="Entreprise unipersonnelle à responsabilité limitée">{props.t("Entreprise unipersonnelle à responsabilité limitée")}</option>
                                                                <option value="Société en nom collectif">{props.t("Société en nom collectif")}</option>
                                                                <option value="Société en commandite simple">{props.t("Société en commandite simple")}</option>
                                                                <option value="Société en participation">{props.t("Société en participation")}</option>
                                                                <option value="Société en formation">{props.t("Société en formation")}</option>
                                                                <option value="Partenariat">{props.t("Partenariat")}</option>
                                                                <option value="Groupement d'intérêt économique ">{props.t("Groupement d'intérêt économique")}</option>
                                                            </Input>
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="sitewebInput" className="form-label">{props.t("Site web")}</Label>
                                                            <Input type="text" className="form-control" id="sitewebInput"
                                                                placeholder={props.t("Entrer le site web")}
                                                                defaultValue={companie.siteweb}
                                                                value={siteweb} onChange={(e) => setSiteweb(e.target.value)} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="legal1Input" className="form-label">{props.t("N° légal 1")}</Label>
                                                            <Input type="text" className="form-control" id="legal1Input"
                                                                placeholder={props.t("Entrer le N° d'enregistrement légal 1")}
                                                                defaultValue={companie.num_enreg_legal1}
                                                                value={num_enreg_legal1} onChange={(e) => setNumEnregLegal1(e.target.value)} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="legal2Input" className="form-label">{props.t("N° légal 2")}</Label>
                                                            <Input type="text" className="form-control" id="legal2Input"
                                                                placeholder={props.t("Entrer le N° d'enregistrement légal 2")}
                                                                defaultValue={companie.num_enreg_legal2}
                                                                value={num_enreg_legal2} onChange={(e) => setNumEnregLegal2(e.target.value)} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="capitalSocialInput" className="form-label">{props.t("Capital Social")}</Label>
                                                            <Input type="text" className="form-control" id="capitalSocialInput"
                                                                placeholder={props.t("Entrer le capital social")}
                                                                defaultValue={companie.capitalSocial}
                                                                value={capitalSocial} onChange={(e) => setCapitalSocial(e.target.value)} />
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="logoEntrepriseInput" className="form-label">{props.t("Ajouter une image")}</Label>
                                                            <Input id="logoEntrepriseInput" type="file" accept="image/*" name='logoEntrepriseInput'
                                                                className="profile-img-file-input"
                                                                onChange={(e) => setLogo(e.target.files[0])} />
                                                        </div>
                                                    </Col>

                                                    <Col lg={12}>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <button 
                                                        //     onClick={() => 
                                                        //     updateHandle()
                                                        // } 
                                                        type="button"
                                                                className="btn btn-primary">{props.t("Modifier")}</button>
                                                            <Link to="/apps-crm-entreprise"
                                                                className="btn btn-soft-success">{props.t("Quitter")}</Link>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </TabPane>


                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

CompanieSettings.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(CompanieSettings));