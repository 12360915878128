import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";

import {
  Container,
  Row,
} from "reactstrap";

// RangeSlider
import "nouislider/distribute/nouislider.css";
import DeleteModal from "../../../Components/Common/DeleteModal";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";


//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { red } from "@material-ui/core/colors";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

const Collaborateurs= (props) => {

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []);

  const [collaborateurs, setCollaborateurs] = useState([]);


  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://prod.leconseillerdubtp.com/api/v1/collaborators/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setCollaborateurs(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);


  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);


  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      // dispatch(deleteProducts(element.value));
      del.style.display = 'none';
    });
  };

  const categgory =(j)=>{
    for(let i=0;i<=collaborateurs.length;i++){
      if(j===collaborateurs[i]?.email){
        if(collaborateurs[i].profile){
          const profil= collaborateurs[i].profile
          return profil.first_name + " " + profil.first_name ;
        }else{
          return " "
        }
      }
    }
  }

  const type =(j)=>{
    for(let i=0;i<=collaborateurs.length;i++){
      if(j===collaborateurs[i]?.email){
        if(collaborateurs[i].profile){
          const profil= collaborateurs[i].profile
          return profil.user_type;
        }else{
          return " "
        }
      }
    }
  }

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".entrepriseCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };



  const columns = useMemo(() => [
    {
      Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
      Cell: (cellProps) => {
        return <input type="checkbox" className="entrepriseCheckBox" value={cellProps.row.original.id} />;
      },
      id: '#',
    },

    {
      Header: props.t("IDENTIFIANT"),
      accessor:"username",
      Cell: (article) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
               <h5 className="fs-14 mb-1 italic" style={{fontWeight:'bold'}}>
                <Link 
                  to={`/apps-collaborateurs-details/${article.row.original.id}/`}
                  className='text-black'
                >
                  {" "}
                  {article.row.original.username}
                </Link>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t("EMAIL"),
      accessor:"email",
      Cell: (article) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1" >
              <h5 className="fs-14 mb-1 ">
              <span>{article.row.original.email}</span>
              </h5> 
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t("TEL"),
      accessor:"phone_number",
      Cell: (article) => (
        <>
          <div className="d-flex align-items-center" >
            <div className="flex-grow-1" >
              <h5 className="fs-14 mb-1 " >
                <Link
                  to={`/#/${article.row.original.id}/`}
                  className="text-dark"
                >
                </Link>
                  {article.row.original.phone_number}
              </h5>
              
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t("Collaborateur"),
      Cell: (article) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1" >
              <h5 className="fs-14 mb-1"style={{fontWeight:"bold",textTransform:"uppercase",textAlign:"center"}}>
                <Link
                  to={`/#/${article.row.original.id}/`}
                  className="text-dark"
                >
                </Link>
                <span className="badge badge-soft-warning me-1">{type(article.row.original.email)}</span>
              </h5>
              
            </div>
          </div>
        </>
      ),
    },
   
    {
      Header: "Action",
      Cell: (cellProps) => {
        const articlesData = cellProps.row.original;
        return(
          <>
            <li className="list-inline-item" title={props.t("Voir")}>
              <Link to={`apps-collaborateurs-details/${articlesData.id}/`} className="text-warning d-inline-block"
                    >
                  <i className="ri-eye-fill align-bottom"></i>
              </Link>
            </li>
          </> 
        );
      },
    },
    
  ],
    [props,categgory,type]
  );


  document.title = props.t("Liste des collaborateurs");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />

      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={props.t("Liste des collaborateurs")} pageTitle={props.t("Liste des collaborateurs")} />

        <Row>
          <div className="col-xl-12 col-lg-12">
          
            <div>
              <div className="card">
                <div className="card-body pt-0">
                  {  isLoading ? ( // If loading, show loading indicator
                      <div className="py-4 text-center">
                        <div>
                          <lord-icon
                              src="https://cdn.lordicon.com/xjovhxra.json"
                              trigger="loop"
                              colors="primary:#0960b6,secondary:#02376b"
                              style={{ width: "72px", height:"72px" }}>
                          </lord-icon>
                        </div>
                        <div className="mt-4">
                          <h5>{props.t("Chargement")}...</h5>
                        </div>
                      </div>
                    ) 
                    :
                  collaborateurs ? (
                    <div>
                    {/* <select className='form-select'
                      onChange={(e) => setSelectedCategory(e.target.value ? parseInt(e.target.value, 10) : null)}
                      value={selectedCategory || ''}
                      >
                      <option value="">Toutes les catégories</option>
                      {categoryList.map((category) => (
                      <option key={category.id} value={category.id}>
                          {category.designation}
                      </option>
                      ))}
                    </select> */}
                    <TableContainer
                      columns={columns}
                      data={(collaborateurs|| [])}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      divClass="table-responsive mb-1"
                      tableClass="mb-0 align-middle table-borderless"
                      theadClass="table-light text-muted"
                      isCollaborateurFilter={true}
                    />
                    </div>
                    
                  ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5>{props.t("Désolé, pas de resultats trouvés")}</h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

Collaborateurs.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(Collaborateurs))