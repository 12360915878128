import React, {useState} from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import '@react-pdf-viewer/core/lib/styles/index.css';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import {
  Card,
  Col,
  Container,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";

// Redux

import { Link, useHistory } from "react-router-dom";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const AppelsdOffresAdd= (props) => {
 
  document.title = "Création d'un appel d'offre";
  const history = useHistory();

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const url = "https://prod.leconseillerdubtp.com/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;


  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleImageChange = (event) => {
    const image= event.target.files[0];
    setSelectedImage(image);
  };
  

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      "title": "",
      "description": "",
      "publication_link": "",
      "publication_date": "",
      "closure_date": "",
      "reference": "",
      "offer_file": selectedFile,
      "offer_pic": selectedImage,
      "closed": false
  },
    
    validationSchema: Yup.object().shape({
      title:Yup.string().required("Le champ est requis !"),
      description:Yup.string().required("Le champ est requis !"),
    }),

    onSubmit: (values) => {
          const formData= new FormData();
          formData.append("title",values.title);
          formData.append("description",values.description);
          formData.append("publication_link",values.publication_link);
          formData.append("publication_date",values.publication_date);
          formData.append("closure_date",values.closure_date);
          formData.append("reference",values.reference);
          formData.append("offer_file",values.offer_file);
          formData.append("offer_pic",values.offer_pic);
          axios.post(`${url}/appel-offres/`,formData)
          .then(res =>{
            if(res){
              toast.success(props.t("Appel d'offre bien ajoutée"),{autoClose:3000})
            }
          }, (error) =>{
            if(error){
              toast.error(props.t("Appel d'offre non ajoutée"),{autoClose:3000})
            }}
          )
          validation.resetForm();
          history.push("/apps-appels-offres");
          setTimeout(()=>window.location.reload(true),2000);
    }
  });
  
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.t("Création d'un appel d'offre")} pageTitle={props.t("Appel d'offre")} />
        <Form encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit(); 
              }}
              >
        <Row>
        
          <Col lg={12}>
          <Card className="p-4">
                  <Row>
                    <Col xl={6}>
                      <div className="mb-3">
                            <Label className="form-label" htmlFor="photo">
                              {props.t("IMAGE")} <span style={{color: "red"}}>*Tous les champs sont obligatoires</span>
                            </Label>
                                <div>
                                      <div>
                                        <input name="offer_pic"
                                          className="form-control"
                                          type="file"
                                          accept="image/jpeg"
                                          capture="file"
                                          onChange={handleImageChange}
                                          required
                                          />
                                      </div>
                                      <div className="pt-4">
                                      {selectedImage && (
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                          {
                                            <img src={URL.createObjectURL(selectedImage)} alt="Aperçu" style={{ maxWidth: '50%'}} />
                                          }
                                        </div>
                                      )}
                                      </div>
                                </div>
                            {validation.errors.offer_pic && validation.touched.offer_pic? (
                              <FormFeedback type="invalid">{props.t(validation.errors.pic)}</FormFeedback>
                            ) : null}
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="mb-3">
                      <Label className="form-label" htmlFor="fichier">
                        {props.t("FICHIER")} <span style={{color: "red"}}>*</span>
                      </Label>
                        <div>
                              <div>
                                <input name="offer_file"
                                  className="form-control"
                                  type="file"
                                  accept=".pdf"
                                  capture="file"
                                  onChange={handleFileChange}
                                  required
                                  />
                                  
                              </div>
                              <div>

                              {selectedFile && (
                                <div >
                                  {
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <a className="text-xs rounded" href={selectedFile} title="Télécharger"><i style={{fontSize: '100px',width:"50%",height:"50%", color:"red" }} className="ri-file-download-fill align-bottom"></i></a>
                                    </div>
                                  }
                                </div>
                              )}
                              </div>
                        </div>
                    {validation.errors.offer_file && validation.touched.offer_file? (
                      <FormFeedback type="invalid">{props.t(validation.errors.offer_file)}</FormFeedback>
                    ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      <div className="mb-3">
                      <Label className="form-label" htmlFor="reference">
                        {props.t("REFERENCE")} <span style={{color: "red"}}>*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form"
                        placeholder={props.t("Entrer la reference de l'offre")}
                        name="reference"
                        value={validation.values.reference}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                      />
                      </div>
                    </Col>
                    <Col xl={6}>
                        <div className="mb-3">
                        <Label className="form-label" htmlFor="title">
                          {props.t("TITRE DE L'OFRRE")}  <span style={{color: "red"}}>*</span>
                        </Label>
                        <Input
                          style={{textTransform:'uppercase',fontWeight:'bold'}}
                          type="text"
                          className="form"
                          name="title"
                          value={validation.values.title}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={validation.errors.title && validation.touched.title ? true : false}
                        />
                        {validation.errors.title && validation.touched.title? (
                          <FormFeedback type="invalid">{props.t(validation.errors.title)}</FormFeedback>
                        ) : null}
                        </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="category">
                          {props.t("DATE DE PUBLICATION")}  <span style={{color: "red"}}>*</span>
                        </Label>
                        <Input
                          type="date"
                          className="form-control"
                          name="publication_date"
                          value={validation.values.publication_date}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={validation.errors.publication_date && validation.touched.publication_date ? true : false}
                        />
                        {validation.errors.publication_date && validation.touched.publication_date? (
                          <FormFeedback type="invalid">{props.t(validation.errors.publication_date)}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="mb-3">
                      <Label className="form-label" htmlFor="">
                        {props.t("DATE D'ECHEANCE")}  <span style={{color: "red"}}>*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        name="closure_date"
                        min={validation.values.publication_date}
                        value={validation.values.closure_date}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        invalid={validation.errors.closure_date && validation.touched.closure_date ? true : false}
                      />
                      {validation.errors.closure_date && validation.touched.closure_date? (
                        <FormFeedback type="invalid">{props.t(validation.errors.closure_date)}</FormFeedback>
                      ) : null}
                      </div>
                    </Col>
                  </Row>
                 
                  <div className="mb-3">
                      <Label className="form-label" htmlFor="description"> {props.t("DESCRIPTION")}  <span style={{color: "red"}}>*</span></Label>
                      <textarea
                        className="form-control"
                        name="description"
                        value={validation.values.description}
                        onChange={validation.handleChange}
                        rows={4} // Nombre de lignes
                        cols={50} // Nombre de colonnes
                        placeholder="Saisissez votre texte ici..."
                        onBlur={validation.handleBlur}
                      ></textarea>
                      
                      {validation.errors.description && validation.touched.description? (
                        <FormFeedback type="invalid">{props.t(validation.errors.description)}</FormFeedback>
                      ) : null}
                  </div>
                    
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="publication_link">
                      {props.t("LIEN")}  <span style={{color: "red", borderTop:'10px'}}>* (Format du lien : https://www.example.com) </span>
                    </Label>
                    <Input
                      type="url"
                      className="form"
                      placeholder={props.t("Entrer l'url de l'article")}
                      name="publication_link"
                      value={validation.values.publication_link}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      invalid={validation.errors.publication_link && validation.touched.publication_link ? true : false}
                    />
                    {validation.errors.title && validation.touched.publication_link? (
                      <FormFeedback type="invalid">{props.t(validation.errors.publication_link)}</FormFeedback>
                    ) : null}
                  </div>
              </Card>
           
             <div className="text-end mb-3">
             <button className="btn btn-primary mr-3">
                  <Link
                    to={`apps-appels-offres`}
                    className="text-white"
                  >
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning m-3">
                   {props.t("Ajouter")}
                </button>
              </div> 
          </Col>
        </Row>
   </Form>
      </Container>
    </div>
  );
};

AppelsdOffresAdd.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(AppelsdOffresAdd));

