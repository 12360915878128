import axios from "axios";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useParams, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
SwiperCore.use([FreeMode, Navigation, Thumbs]);

function ArticleValidation(props) {
  const articlesId = useParams("id");
  const history = useHistory();
  const token = JSON.parse(sessionStorage.getItem("authUser"))
    ? JSON.parse(sessionStorage.getItem("authUser")).access
    : null;

  const [selectCategory, setSelectCategory] = useState([]);
  const [selectAticle, setSelectArticle] = useState([]);
  const url = "https://prod.leconseillerdubtp.com/api/v1";
  const [articleListApp, setArticleListApp] = useState([
    { title: "", comments: [{ id: null, replies: [] }], likes_count: "" },
  ]);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get(`${url}/categories/`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        setSelectCategory(response);
      } catch (error) {
        console.log(error);
      }
    };
    checkTokenValidity();
  }, []);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get(
          "https://prod.leconseillerdubtp.com/api/v1/article-list/",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setArticleListApp(response);
      } catch (error) {
        console.log(error);
      }
    };
    checkTokenValidity();
  }, []);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get(`${url}/articles/${articlesId.id}/`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        setSelectArticle(response);
      } catch (error) {
        console.log(error);
      }
    };
    checkTokenValidity();
  }, []);

  const categgory = (j) => {
    for (let i = 0; i <= selectCategory.length; i++) {
      if (j === selectCategory[i]?.id) {
        return selectCategory[i]?.designation;
      }
    }
  };

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  document.title = props.t("Validation d'un article");
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t("Validation d'un article")}
          pageTitle={props.t("Article")}
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="gx-lg-5">
                  <Col xl={12}>
                    <div className="mt-xl-0 mt-5">
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={selectAticle.article_pic}
                          className="rounded"
                          style={{ height: "30%", width: "100%" }}
                          alt="Article Picture"
                        />
                      </div>
                      <div className="d-flex">
                        <div className="flex-wrap flex-1 gap-3">
                          <br></br>
                          <h2> {selectAticle.title}</h2>
                          <span className="text-xl fw-medium badge-soft-warning me-1 p-1 rounded">
                            {categgory(selectAticle.category)}
                          </span>
                        </div>
                      </div>
                      <div
                        className="mt-4 text-muted"
                        style={{ textAlign: "justify" }}
                      >
                        <p>{selectAticle.content}</p>
                      </div>

                      <div className="product-content mt-5">
                        <Nav tabs className="nav-tabs-custom nav-success">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "1",
                              })}
                              onClick={() => {
                                toggleCustom("1");
                              }}
                            >
                              {props.t("Détails")}
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className="border border-top-0 p-4"
                          id="nav-tabContent"
                        >
                          <TabPane id="nav-speci" tabId="1">
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row">
                                      {" "}
                                      {props.t("Date de création")}
                                    </th>
                                    <td
                                      className="text-blue-500"
                                      style={{ color: "blue" }}
                                    >
                                      {selectAticle.created_at}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> {props.t("Auteur")} </th>
                                    <td
                                      className="text-red-500"
                                      style={{ color: "red" }}
                                    >
                                      {selectAticle.author &&
                                        selectAticle.author.username}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

ArticleValidation.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(ArticleValidation));
