import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Container, Row } from "reactstrap";

import "nouislider/distribute/nouislider.css";
import DeleteModal from "../../Components/Common/DeleteModal";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";

//redux
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { red } from "@material-ui/core/colors";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const Recrutements = (props) => {
  const token = JSON.parse(sessionStorage.getItem("authUser"))
    ? JSON.parse(sessionStorage.getItem("authUser")).access
    : null;
  const dispatch = useDispatch();
  const url = "https://prod.leconseillerdubtp.com/api/v1";

  // Definition des etats
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const [recrutements, setRecrutements] = useState([
    { id: "", offer_file: null },
  ]);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get(
          "https://prod.leconseillerdubtp.com/api/v1cruitments/",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setRecrutements(response);
      } catch (error) {
        console.log(error);
      }
    };
    checkTokenValidity();
  }, []);

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const onClickDelete = (product) => {
    setRecrutements(product);
    setDeleteModal(true);
  };

  const handleDeleteProduct = () => {
    if (recrutements) {
      // dispatch(deleteProducts(articles.id));
      setDeleteModal(false);
    }
  };

  const role = JSON.parse(sessionStorage.getItem("authUser"))?.role.some(
    (role) => role === "assistant"
  );

  const [dele, setDele] = useState(0);

  // Displat Delete Button
  const displayDelete = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    setDele(ele.length);
    if (ele.length === 0) {
      del.style.display = "none";
    } else {
      del.style.display = "block";
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      // dispatch(deleteProducts(element.value));
      del.style.display = "none";
    });
  };

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".entrepriseCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="entrepriseCheckBox"
              value={cellProps.row.original.id}
            />
          );
        },
        id: "#",
      },
      {
        Header: props.t("REF"),
        accessor: "reference",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center ">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 italic">
                  <Link className="text-dark">
                    {" "}
                    {recruitments.row.original.reference}
                  </Link>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("ILLUSTRATION"),
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <img
                  src={recruitments.row.original.offer_pic}
                  alt=""
                  className="avatar-sm rounded"
                />
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("TITRE"),
        accessor: "title",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center ">
              <div className="flex-grow-1">
                <h5
                  className="fs-14 mb-1 uppercase"
                  style={{ textTransform: "uppercase", fontWeight: "bold" }}
                >
                  {recruitments.row.original.title}
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("DESCRIPTIF"),
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5
                  className="fs-14 mb-1 "
                  style={{
                    width: 200,
                    height: 50,
                    overflow: "clip",
                    verticalAlign: "middle",
                  }}
                >
                  <Link
                    to={`/#/${recruitments.row.original.id}/`}
                    className="text-dark"
                  ></Link>
                  {recruitments.row.original.description}
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("PUBLIE"),
        accessor: "publication_date",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="">
                <h5
                  className=" mb-1 badge badge-soft-primary"
                  style={{ textAlign: "center" }}
                >
                  <Link
                    to={`/#/${recruitments.row.original.id}/`}
                    className="text-dark "
                  ></Link>
                  {recruitments.row.original.publication_date}
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("CLOTURE"),
        accessor: "closure_date",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                {new Date() >
                new Date(recruitments.row.original.closure_date) ? (
                  <h5
                    className="mb-1 badge badge-soft-danger "
                    style={{ textAlign: "center" }}
                  >
                    <Link
                      to={`/#/${recruitments.row.original.id}/`}
                      className="text-dark "
                    ></Link>
                    {recruitments.row.original.closure_date}
                  </h5>
                ) : (
                  <h5
                    className="mb-1 badge badge-soft-warning "
                    style={{ textAlign: "center" }}
                  >
                    <Link
                      to={`/#/${recruitments.row.original.id}/`}
                      className="text-dark "
                    ></Link>
                    {recruitments.row.original.closure_date}
                  </h5>
                )}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("STATUT"),
        accessor: "closed",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                {new Date() >
                new Date(recruitments.row.original.closure_date) ? (
                  <h5
                    className=" mb-1 badge badge-soft-danger "
                    style={{ textAlign: "center" }}
                  >
                    <Link
                      to={`/#/${recruitments.row.original.id}/`}
                      className="text-dark "
                    ></Link>
                    {"Cloturé"}
                  </h5>
                ) : (
                  <h5
                    className="mb-1 badge badge-soft-success me-1 "
                    style={{ textAlign: "center" }}
                  >
                    <Link
                      to={`/#/${recruitments.row.original.id}/`}
                      className="text-dark "
                    ></Link>
                    {"En cours"}
                  </h5>
                )}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("FICHIER"),
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                {recruitments.row.original.offer_file ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      className="text-xs rounded"
                      href={recruitments.row.original.offer_file}
                      target="_blank"
                      title="Télécharger"
                    >
                      <i
                        style={{
                          fontSize: "30px",
                          width: "50%",
                          height: "50%",
                          color: "red",
                        }}
                        className="ri-file-download-fill align-bottom"
                      ></i>
                    </a>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <i
                      style={{ fontSize: "30px", width: "50%", height: "50%" }}
                      className="ri-link align-bottom"
                    ></i>
                  </div>
                )}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("STATUT"),
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14  p-2">
                  <span className="mt-4">
                    {recruitments.row.original.published === false && !role ? (
                      <div>
                        <div className="btn btn-soft-success">
                          <Link
                            to={`apps-recrutements-details/${recruitments.row.original.id}/`}
                          >
                            Publier
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <h5
                        className="mb-1 badge badge-soft-success "
                        style={{ fontSize: "10px" }}
                      >
                        Publiée
                      </h5>
                    )}
                  </span>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "ACTIONS",
        Cell: (cellProps) => {
          const recruitmentsData = cellProps.row.original;
          return (
            <div>
              <li className="list-inline-item" title={props.t("Modifier")}>
                <Link
                  to={`apps-recrutements-update/${recruitmentsData.id}/`}
                  className="text-warning d-inline-block"
                >
                  <i className="ri-pencil-fill align-bottom"></i>
                </Link>
              </li>

              <li className="list-inline-item" title={props.t("Voir")}>
                <Link
                  to={`apps-recrutements-details/${recruitmentsData.id}/`}
                  className="text-warning d-inline-block"
                >
                  <i className="ri-eye-fill"></i>
                </Link>
              </li>
            </div>
          );
        },
      },
    ],
    [props]
  );

  document.title = props.t("Liste des offres d'emplois");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb
          title={props.t("Liste des offres d'emploi")}
          pageTitle={props.t("Listes des offres d'emploi")}
        />
        <Row>
          <div className="col-xl-12 col-lg-12">
            <div>
              <div className="card">
                <div className="card-body pt-0">
                  {isLoading ? (
                    <div className="py-4 text-center">
                      <div>
                        <lord-icon
                          src="https://cdn.lordicon.com/xjovhxra.json"
                          trigger="loop"
                          colors="primary:#0960b6,secondary:#02376b"
                          style={{ width: "72px", height: "72px" }}
                        ></lord-icon>
                      </div>
                      <div className="mt-4">
                        <h5>{props.t("Chargement")}...</h5>
                      </div>
                    </div>
                  ) : recrutements ? (
                    <TableContainer
                      columns={columns}
                      data={recrutements || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      divClass="table-responsive mb-1"
                      tableClass="mb-0 align-middle table-borderless"
                      theadClass="table-light text-muted"
                      isRecrutementsFilter={true}
                    />
                  ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5>{props.t("Désolé, pas de resultats trouvés")}</h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};
Recrutements.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(Recrutements));
