import axios from "axios";
import classnames from "classnames";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useParams, withRouter } from "react-router-dom";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import * as Yup from "yup";
import BreadCrumb from "../../Components/Common/BreadCrumb";
SwiperCore.use([FreeMode, Navigation, Thumbs]);

function ExpertValidation(props) {
  const articlesId = useParams("id");
  const history = useHistory();
  const token = JSON.parse(sessionStorage.getItem("authUser"))
    ? JSON.parse(sessionStorage.getItem("authUser")).access
    : null;

  const [selectAticle, setSelectArticle] = useState({});
  const url = "https://prod.leconseillerdubtp.com/api/v1";
  const [competences, setCompetences] = useState([]);
  const [references, setReferences] = useState([]);

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get(
          "https://prod.leconseillerdubtp.com/api/v1ers/list/",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setUserList(response);
      } catch (error) {
        console.log(error);
      }
    };
    checkTokenValidity();
  }, []);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get(`${url}/experts/${articlesId.id}/`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        setSelectArticle(response);
        setCompetences(selectAticle.competences);
        setReferences(selectAticle.references);
      } catch (error) {
        console.log(error);
      }
    };
    checkTokenValidity();
  }, [selectAticle]);

  const users = (j) => {
    for (let i = 0; i <= userList.length; i++) {
      if (userList[i] && j === userList[i]?.id) {
        const name = userList[i]?.profile;
        return name.last_name + " " + name.first_name;
      }
    }
  };

  const email = (j) => {
    for (let i = 0; i <= userList.length; i++) {
      if (userList[i] && j === userList[i]?.id) {
        return userList[i]?.email;
      }
    }
  };
  const photo = (j) => {
    for (let i = 0; i <= userList.length; i++) {
      if (userList[i] && j === userList[i]?.id) {
        const photoProfile = userList[i]?.profile;
        return photoProfile.profile_pic;
      }
    }
  };

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      is_expert: false,
    },

    validationSchema: Yup.object({
      validated: Yup.boolean(),
    }),

    onSubmit: (values) => {
      const validatedObject = {
        id: selectAticle.id,
        is_expert: values.is_expert,
      };
      axios
        .patch(`${url}/experts/${articlesId.id}/`, validatedObject)
        .then((res) => {
          if (res) {
            toast.success("Expert validé avec succes", { autoClose: 3000 });
            history.push("/apps-experts");
          } else {
            console.log(res.status);
          }
        });

      history.push("/apps-experts");
      validation.resetForm();
      setTimeout(() => window.location.reload(true), 2000);
    },
  });

  document.title = props.t("Validation d'un expert");
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t("Validation d'un expert")}
          pageTitle={props.t("Experts")}
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="gx-lg-5">
                  <Col xl={12}>
                    <div className="mt-xl-0 mt-5">
                      <div>
                        <div className="d-flex">
                          <img
                            src={photo(selectAticle.user)}
                            width="100"
                            style={{
                              borderRadius: "50%",
                              height: "100px",
                              width: "100px",
                              objectFit: "cover",
                              objectPosition: "center center",
                              paddingBottom: "10px",
                            }}
                            alt=""
                          />
                          <div
                            style={{
                              marginLeft: "20px",
                              marginTop: "8px",
                            }}
                          >
                            <h2
                              style={{
                                fontWeight: "bold",
                                textTransform: "uppercase",
                              }}
                            >
                              {users(selectAticle.user)}
                            </h2>
                            <span className="text-xl fw-medium">
                              <p>{selectAticle.profession}</p>
                              <p className="text-muted">
                                {selectAticle.last_certificate}
                              </p>
                            </span>
                            <br />
                          </div>
                        </div>
                      </div>

                      <span className="text-xl fw-medium">
                        {competences &&
                          competences.map((item, key) => {
                            return (
                              <div
                                className="text-xl fw-medium badge badge-soft-warning"
                                key={key}
                              >
                                {item.label}
                              </div>
                            );
                          })}
                      </span>

                      <div className="product-content mt-3">
                        <Nav tabs className="nav-tabs-custom nav-success">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "1",
                              })}
                              onClick={() => {
                                toggleCustom("1");
                              }}
                            >
                              {props.t("Détails")}
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className="border border-top-0 p-4"
                          id="nav-tabContent"
                        >
                          <TabPane id="nav-speci" tabId="1">
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row">
                                      {" "}
                                      {props.t("Curriculum Vitae")}
                                    </th>
                                    <td>
                                      {selectAticle.cv ||
                                        props.t("(Non Renseigné)")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      {" "}
                                      {props.t("Références")}{" "}
                                    </th>
                                    <td>
                                      {(references &&
                                        references.map((item, key) => {
                                          return (
                                            <span
                                              className="text-xl fw-medium "
                                              key={key}
                                            >
                                              {item.phone_number}
                                            </span>
                                          );
                                        })) ||
                                        props.t("(Non Renseigné)")}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Form
                  encType="multipart/form-data"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                  }}
                >
                  <br></br>
                  <input
                    type="checkbox"
                    name="is_expert"
                    className="form"
                    style={{
                      fontWeight: "30px",
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                    checked={validation.values.is_expert}
                    onChange={validation.handleChange}
                  />
                  <b> Je valide cet expert </b>
                  <div className="text-end mb-3">
                    <button className="btn btn-primary mr-6">
                      <Link to={`/apps-experts`} className="text-white">
                        {props.t("Retour")}
                      </Link>
                    </button>
                    <button type="submit" className="btn btn-warning">
                      {props.t("Valider l'expert")}
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

ExpertValidation.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(ExpertValidation));
