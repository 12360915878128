import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router-dom";
import { Container, Row } from "reactstrap";

// RangeSlider
import "nouislider/distribute/nouislider.css";
import DeleteModal from "../../Components/Common/DeleteModal";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
//Import data

//Import actions
import { deleteProductsCategories } from "../../store/ecommerce/action";

//redux
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { API_URL as url } from "../../Components/constants/all";
import renderUerTypeLabel from "../../utils/renderUerTypeLabel";
import { axiosIns } from "../utils";

const UsersCategories = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [categories, setCategoriesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const data = await axiosIns.get(`${url}/user-categories/`);
      if (data?.status === 200) {
        setCategoriesList(data?.data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  const [category, setCategory] = useState(null);

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const onClickDelete = (category) => {
    setCategory(category);
    setDeleteModal(true);
  };

  const handleDeleteCategory = () => {
    if (category) {
      dispatch(deleteProductsCategories(category._id));
      setDeleteModal(false);
    }
  };

  const [dele, setDele] = useState(0);
  // Displat Delete Button
  const displayDelete = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    setDele(ele.length);
    if (ele.length === 0) {
      del.style.display = "none";
    } else {
      del.style.display = "block";
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      dispatch(deleteProductsCategories(element.value));
      del.style.display = "none";
    });
  };
  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".entrepriseCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="entrepriseCheckBox"
              value={cellProps.row.original.id}
            />
          );
        },
        id: "#",
      },
      {
        Header: props.t("CATEGORIE"),
        accessor: "designation",
        Cell: (category) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <p className="fs-14 mb-1">{category.row.original.name}</p>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("Type d'utilisateur"),
        accessor: "user_type",
        Cell: (category) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <p className="fs-14 mb-1">
                  {renderUerTypeLabel(category.row.original.user_type)}
                </p>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "ACTIONS",
        Cell: (cellProps) => {
          const categoriesData = cellProps.row.original;
          return (
            <li className="list-inline-item" title={props.t("Modifier")}>
              <Link
                to={`apps-utilisateurs-categories-update/${categoriesData.id}/`}
                className="text-warning d-inline-block"
              >
                <i className="ri-pencil-fill align-bottom" />
              </Link>
            </li>
          );
        },
      },
    ],
    [props]
  );

  document.title = props.t("Catégorie d'articles");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb
          title={props.t("Catégories")}
          pageTitle={props.t("Categories d'articles")}
        />

        <Row>
          <div className="col-xl-12 col-lg-12">
            <div>
              <div className="card">
                <div className="card-body pt-0">
                  {isLoading ? (
                    <div className="py-4 text-center">
                      <div>
                        <lord-icon
                          src="https://cdn.lordicon.com/xjovhxra.json"
                          trigger="loop"
                          colors="primary:#0960b6,secondary:#02376b"
                          style={{ width: "72px", height: "72px" }}
                        ></lord-icon>
                      </div>
                      <div className="mt-4">
                        <h5>{props.t("Chargement")}...</h5>
                      </div>
                    </div>
                  ) : categories && categories.length >= 0 ? (
                    <TableContainer
                      columns={columns}
                      data={categories || []}
                      isGlobalFilter={true}
                      isAddUserCategorie={true}
                      customPageSize={10}
                      divClass="table-responsive mb-1"
                      tableClass="mb-0 align-middle "
                      theadClass="table-light text-muted"
                    />
                  ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5> {props.t("Désolé, pas de resultats trouvés")} </h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

UsersCategories.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UsersCategories));
