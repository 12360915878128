export const API_URL = process.env.REACT_APP_API_URL;
export const requiredString = "Ce champs est requis";
export const emailInvalid = "L'adresse email n'est pas valide";
export const passwordInvalid = {
  first: "Le mot de passe doit contenir au moins 8 caractères",
  second: "Le mot de passe doit contenir au moins une lettre majuscule",
  third: "Le mot de passe doit contenir au moins une lettre minuscule",
  fourth: "Le mot de passe doit contenir au moins un chiffre",
  fifth:
    "Le mot de passe doit contenir au moins un caractère spécial (@, $, !, %, *, ?, &)",
};
export const confirmPasswordInvalid = "Les mots de passe doivent correspondre";
export const phoneNumberInvalid = "Numéro de téléphone invalide"

export const DataUserType = [
  {
    label: "Particulier",
    value: "particular",
  },
  {
    label: "Professionnel du BTP",
    value: "professional",
  },
  {
    label: "Entreprise",
    value: "enterprise",
  },
  {
    label: "Organisme",
    value: "organism",
  },
];
