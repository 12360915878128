import React, { useEffect, useState, useMemo } from "react";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import axios from "axios";
import {
  Container,
  Form,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col,
  FormFeedback
} from "reactstrap";

// RangeSlider
import "nouislider/distribute/nouislider.css";
import DeleteModal from "../../Components/Common/DeleteModal";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback } from "react";
import { toast } from "react-toastify";

//redux
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { red } from "@material-ui/core/colors";



const FonctionsLevel = (props) => {
  const dispatch = useDispatch();

  const url = "https://prod.leconseillerdubtp.com/api/v1";
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const [formationlevelList,setFormationLevelList]=useState([]);
  const [levelListDeleted,setLevelDeleted]=useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/formation-level/`);
          setFormationLevelList(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();
    }, [url]);

  const toggle2 = useCallback(() => {
      if (deleteModal) {
        setDeleteModal(false);
        setLevelDeleted({});
      } else {
        setDeleteModal(true);
      }
      
    }, [deleteModal]);


  const handleDeleteCategory =  useCallback((arg) => {
      setLevelDeleted({
        id:arg.id,
        label:arg.label
      });
      setIsDelete(true)
      if (isDelete){
        try {
          axios.delete(`${url}/formation-level/${levelListDeleted.id}/`).then(res =>{
           if(res){
             toast.success(props.t("Niveau de formation bien supprimée"),{autoClose:3000})
             setTimeout(()=>window.location.reload(true),2000); 
           }
         }
         );
         } catch (err) {
           console.log(err);
         }
      }
      toggle2()
    }, [toggle2,url]);


  const [modal, setModal] = useState(false);
  const [type, setType] = useState({});

  const toggle = useCallback(() => {
      if (modal) {
        setModal(false);
        setType({});
      } else {
        setModal(true);
      }
    }, [modal]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []); 

  const [dele, setDele] = useState(0);
  // Displat Delete Button
  const displayDelete = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    setDele(ele.length);
    if (ele.length === 0) {
      del.style.display = 'none';
    } else {
      del.style.display = 'block';
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      dispatch(element.value);
      del.style.display = 'none';
    });
  };

  const niveauxFormation = [
    { label: 'Bac', value: 'Bac' },
    { label: 'Bac+1', value: 'Bac+1' },
    { label: 'Bac+2', value: 'Bac+2' },
    { label: 'Bac+3', value: 'Bac+3' },
    { label: 'Bac+4', value: 'Bac+4' },
    { label: 'Bac+5', value: 'Bac+5' },
    { label: 'Master', value: 'Master' },
    { label: 'Doctorat', value: 'Doctorat' },
    { label: 'Autre', value: 'Autre' }
  ];

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      "label":(type && type.label )|| " "
    },
    
    validationSchema: Yup.object().shape({
      label: Yup.string().required(props.t("Entrer un niveau de formation")),
    }),

    onSubmit: (values) => {
      if(isEdit===false){
        const newCategory={
          label:values.label
        }
  
        var count=0;
          if (newCategory){
            for (let i=0; i<=formationlevelList.length;i++){
              if(newCategory.label=== formationlevelList[i]?.label){
                count= count+1;
                toast.error(props.t("Le niveau de formation existe deja"), { autoClose: 3000 });
                break;
              }
            }
            if (count===0){
              axios.post(`${url}/formation-level/`,newCategory)
              .then(res =>{
                if(res){
                  toast.success(props.t("Niveau de formation bien ajoutée"),{autoClose:3000})
                }
              }, (error) =>{
                if(error){
                  toast.error(props.t("Niveau de formation non ajoutée"),{autoClose:3000})
                }}
              )
              validation.resetForm();
              setModal(false);
              setTimeout(()=>window.location.reload(true),2000); 
            }else{
              count=0;
            }
          }
      } else{
        const updateCategory={
          id: type && type.id ,
          label:values.label
        }
        var compte=0;
          if (updateCategory){
            for (let i=0; i<=formationlevelList.length;i++){
              if(updateCategory.label=== formationlevelList[i]?.label){
                compte= compte+1;
                toast.error(props.t("La fonction existe deja"), { autoClose: 3000 });
                break;
              }
            }
            if (compte===0){
              axios.patch(`${url}/formation-level/${updateCategory.id}/`,updateCategory)
              .then(res =>{
                if(res){
                  toast.success(props.t("Niveau de formation bien modifiée"),{autoClose:3000})
                }
              }, (error) =>{
                if(error){
                  toast.error(props.t("Niveau de formation non modifiée"),{autoClose:3000})
                }}
              )
              validation.resetForm();
              setModal(false);
              setTimeout(()=>window.location.reload(true),2000); 
            }else{
              compte=0;
            }
          }

      }

  } 
  });

  useEffect(() => {
    if(formationlevelList)
      return
  }, [formationlevelList]);


  const handleContactClick = useCallback((arg) => {
    setType({
      id:arg.id,
      label:arg.label
    });
    setIsEdit(true);
    toggle();
  }, [toggle]);

  const columns = useMemo(() => [
    {
      Header: props.t("NIVEAU DE FORMATION"),
      accessor:"label",
      Cell: (fonctions) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <p className="fs-14 mb-1">
                <Link
                  to="/apps-fonctions"
                  className="text-dark"
                >
                  {" "}
                  {fonctions.row.original.label}
                </Link>
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: "ACTION",
      Cell: (cellProps) => {
        const fonctionsData = cellProps.row.original;
        return(
          <div>
            <li className="list-inline-item" title={props.t("Modifier")}>
              <a  className="text-warning d-inline-block" style={{cursor:'pointer'}}  onClick={() => {handleContactClick(fonctionsData);}}
                    >
                    <i className="ri-pencil-fill align-bottom"></i>
                </a >
              </li>
              {/* <li className="list-inline-item" title={props.t("Supprimer")}>
              <a  className="text-danger d-inline-block" style={{cursor:'pointer'}}  onClick={() => {handleDeleteCategory(fonctionsData);}}
                    >
                    <i className="ri-delete-bin-6-line"></i>
                </a >
              </li>  */}
            </div> 
        );
      },
    },
  ],
    [props,handleContactClick,handleDeleteCategory]
  );


  document.title = props.t("Liste des fonctions");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={props.t("Niveau de formation")} pageTitle={props.t("Niveau de formation")}/>
        <Row>
          <div className="col-xl-12 col-lg-12">
            <div>
              <div className="card">
                <div className="card-header border-0">
                <Row className="g-4 align-items-center">
                    <Col sm={5}>
                      <div className="search-box">
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder="Rechercher..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                      <div className="col-sm-auto ms-auto">
                          <div>
                              <button className="btn btn-warning" onClick={()=>{setModal(true); setIsEdit(false)}}>  
                                  <i className="ri-add-fill align-bottom me-1"></i> Ajouter un niveau de formation
                              </button>
                          </div>
                      </div>
                  </Row>
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div id="selection-element">
                        <div className="my-n1 d-flex align-items-center text-muted">
                          Select{" "}
                          <div
                            id="select-content"
                            className="text-body fw-semibold px-1"
                          >{dele}</div>{" "}
                          Result{" "}
                          <button
                            type="button"
                            className="btn btn-link link-danger p-0 ms-3"
                            onClick={() => setDeleteModalMulti(true)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <Modal id="showModal1" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
                      {!!isEdit ? props.t("Modifier un niveau de formation") : props.t("Ajouter un niveau de formation")}
                    </ModalHeader>

                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                      <ModalBody>
                        <Input type="hidden" id="id-field" />
                        <Row className="g-3">  
                          <Col lg={12}>
                            <div>
                              <Label
                                htmlFor=""
                                className="form-label"
                              >
                                {props.t("Nom")}
                              </Label>
                               
                              <Input
                                name="label"
                                id="label"
                                className="form-control mb-2"
                                placeholder={props.t("Entrez le nom")}
                                type='text'
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.label || ""}
                                invalid={
                                  validation.touched.label && validation.errors.label ? true : false
                                }
                              >
                             
                                </Input>
                              {validation.touched.label && validation.errors.label ? (
                                <FormFeedback type="invalid">{validation.errors.label}</FormFeedback>
                              ) : null}
                              
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button type="button" className="btn btn-light" onClick={() => { setModal(false); }} > {props.t("Fermer")} </button>
                          <button type="submit" className="btn btn-warning" > {!!isEdit ? props.t("Modifier") : props.t("Ajouter")} </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  {   isLoading ? ( // If loading, show loading indicator
                      <div className="py-4 text-center">
                        <div>
                          <lord-icon
                              src="https://cdn.lordicon.com/xjovhxra.json"
                              trigger="loop"
                              colors="primary:#0960b6,secondary:#02376b"
                              style={{ width: "72px", height:"72px" }}>
                          </lord-icon>
                        </div>
                        <div className="mt-4">
                          <h5>{props.t("Chargement")}...</h5>
                        </div>
                      </div>
                    ) 
                    :
                  formationlevelList && formationlevelList.length > 0 ? (
                    <TableContainer
                      columns={columns}
                      data={(formationlevelList|| [])}
                      isAddUserList={false}
                      customPageSize={10}
                      divClass="table-responsive mb-1"
                      tableClass="mb-0 align-middle "
                      theadClass="table-light text-muted"
                    />
                  ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5> {props.t("Désolé, pas de resultats trouvés")} </h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>

      </Container>
    </div>
  );
};
FonctionsLevel.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(FonctionsLevel));