import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BreadCrumb from "../../Components/Common/BreadCrumb";

import { useFormik } from "formik";
import { registerPlugin } from "react-filepond";
import { useHistory, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { object, string } from "yup";
import { DataUserType, API_URL as url } from "../../Components/constants/all";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import { axiosIns } from "../utils";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const UpdateCategories = (props) => {
  document.title =
    "Mise à jour de catégorie de type d'utilisateurs | Le Conseiller du BTP";

  const history = useHistory();
  const [category, setCategory] = useState({});

  const id = useParams().id;

  const fetchData = async () => {
    try {
      const data = await axiosIns.get(`${url}/user-categories/${id}/`);
      setCategory(data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      designation: category.name,
      user_type: category.user_type,
    },

    validationSchema: object().shape({
      designation: string().required(
        props.t("Entrer la désignation de la catégorie")
      ),
      user_type: string().required(props.t("Choisissez le type d'utilisateur")),
    }),

    onSubmit: (values) => {
      let count = 0;

      const newCategory = {
        name: values.designation,
        user_type: values.user_type,
      };

      if (newCategory) {
        if (count === 0) {
          axiosIns.patch(`${url}/user-categories/${id}/`, newCategory).then(
            (res) => {
              if (res) {
                toast.success(props.t("Catégorie mise à jour avec succès"), {
                  autoClose: 3000,
                });
              }
            },
            (error) => {
              if (error) {
                toast.error(props.t("Catégorie non mise à jour"), {
                  autoClose: 3000,
                });
              }
            }
          );
          validation.resetForm();
          history.push("/apps-utilisateurs-categories");
        } else {
          count = 0;
        }
      }
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t("Mise à jour de catégorie de type d'utilisateurs")}
          pageTitle={props.t("Utilisateurs")}
        />

        <Row>
          <Col lg={12}>
            <Form
              encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}
            >
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="designation">
                      {props.t("Désignation de la catégorie")}
                    </Label>
                    <Input
                      type="text"
                      className="form"
                      placeholder={props.t("Entrer la désignation")}
                      name="designation"
                      value={validation.values.designation}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      required
                    />
                    {validation.errors.designation &&
                    validation.touched.designation ? (
                      <FormFeedback type="invalid">
                        {validation.errors.designation}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <Row>
                    <Col xl={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {" "}
                          {props.t("Type d'utilisateur")}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          className="form-control"
                          name="user_type"
                          type="select"
                          value={validation.values.user_type}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          required
                        >
                          <option value="">
                            Sélectionner le type d'utilisateur
                          </option>
                          {DataUserType.map((item, key) => (
                            <option value={item.value} key={key}>
                              {item.label}
                            </option>
                          ))}
                        </Input>
                        {validation.errors.user_type &&
                        validation.touched.user_type ? (
                          <FormFeedback type="invalid">
                            {validation.errors.user_type}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="text-end mb-3">
                <button className="btn btn-primary mr-3">
                  <Link to={`apps-categories`} className="text-white">
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning w-sm m-2">
                  {props.t("Ajouter")}
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

UpdateCategories.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(UpdateCategories));
