const renderUerTypeLabel = (user_type) => {
  switch (user_type) {
    case "particular":
      return "Particulier";
    case "professional":
      return "Professionnel du BTP";
    case "enterprise":
      return "Entreprise";
    case "organism":
      return "Organisme";
    default:
      return user_type;
  }
};

export default renderUerTypeLabel;
